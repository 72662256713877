// @flow
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ReactSVG } from 'react-svg'
import { humanFileSize } from 'Shared/Common'
import { PlexFile } from 'Shared/Types'
import CardContainer from '../CardContainer/CardContainer'
import CheckBox from '../CheckBox/CheckBox'
import styles from './Plex.css'

type ReduxProps = {
  queue: PlexFile[]
}

const Plex = () => {
  const { queue: initialQueue } = useSelector((state: ReduxProps) => state)
  const [current, setCurrent] = useState(-1)
  const [queue, setQueue] = useState(initialQueue)
  const [faststart, setFastStart] = useState(true)
  const [mp4, setMP4] = useState(true)
  const [operations, setOperations] = useState([])
  const [refreshing, setRefreshing] = useState(false)
  const [loadVideoIndex, setLoadVideoIndex] = useState(-1)
  const [loadVideoLink, setLoadVideoLink] = useState('')

  useEffect(() => {
    document.title = 'Plex - ' + document.title
  }, [])

  const handleOptimizeClick = (index: number) => {
    // Current and new is the same index, reset current to close the box
    setCurrent(current === index ? -1 : index)
  }

  const handleRefreshClick = () => {
    setRefreshing(true)

    fetch('/api/plex/refresh')
      .then(response => response.json())
      .then(response => setQueue(response))
      .finally(() => setRefreshing(false))
  }

  const handleRunClick = (index: number) => {
    const name = queue[index].name

    if (operations.includes(name))
      return

    const newOps = [...operations, name]
    console.log(newOps)
    setOperations(newOps)
  }

  const handleLoadVideoClick = (e: Event, index: number, link: string) => {
    e.preventDefault()

    if (loadVideoIndex !== index) {
      setLoadVideoIndex(index)
      setLoadVideoLink(link)
    } else {
      // Reset
      setLoadVideoIndex(-1)
      setLoadVideoLink('')
    }
  }

  return (
    <>
      <CardContainer title='Queue' wide>
        <div>Queue of currently running operations</div>
        <ul>
          {operations.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </CardContainer>

      <CardContainer className={styles.plex} title='Plex' wide>
        <div>This page will display files that can be optimized for Plex.</div>

        <div className={styles.queueListActions}>
          <div
            className={styles.action}
            data-loop={refreshing}
            onClick={handleRefreshClick}
          >
            <i><ReactSVG src='/public/refresh.svg' /></i>
            <span>Refresh</span>
          </div>
        </div>

        <ul className={styles.queueList}>
          {queue.map((e, index) => {
            return (
              <li key={index}>
                <div className={styles.top}>
                  <div>
                    <a href={e.path} onClick={(e) => handleLoadVideoClick(e, index, e.path)}>{e.name}</a>
                    {loadVideoIndex === index &&
                      <video autoPlay controls> {/* eslint-disable-line react/no-unknown-property */}
                        <source src={e.path} type='video/mp4' />
                      </video>
                    }
                  </div>
                  <div>
                    <label>{humanFileSize(e.size, true)}</label>
                    <label> - </label>
                    <label>{e.duration}</label>
                  </div>
                  <button onClick={() => handleOptimizeClick(index)}>{current === index ? 'Cancel' : 'Optimize'}</button>
                </div>

                {current === index &&
                  <div className={styles.bottom} data-is-open={current === index}>
                    <CheckBox
                      name='faststart'
                      label='-faststart'
                      initiallyChecked
                      onValueChanged={(_, checked) => setFastStart(checked)}
                    />
                    <CheckBox
                      name='mp4'
                      label='mp4'
                      initiallyChecked
                      onValueChanged={(_, checked) => setMP4(checked)}
                    />
                    <button onClick={() => handleRunClick(index)}>Run</button>
                  </div>}
              </li>
            )
          })}
        </ul>
      </CardContainer>
    </>
  )
}

export default Plex
