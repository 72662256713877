// @flow
import { useEffect, useState } from 'react'
import { post, put } from 'Shared/Common'
import styles from './BannerModal.css'
// $FlowFixMe
import { AccountType } from 'Shared/Types'

type Props = {
  account: ?AccountType,
  showModal: boolean,
  onClose: (createdNew: boolean, account: ?AccountType) => void
}

type CheckBoxProps = {
  label: string,
  value: boolean,
  onChange: (value: boolean) => void
}

type InputProps = {
  label: string,
  type: string,
  value: string | number,
  onChange: (value: any) => void,
  placeholder: ?string,
  required: ?boolean
}

const ATTR_MODAL_OPEN = 'data-modalopen'

const CheckBox = (props: CheckBoxProps) => {
  return (
    <label>
      <input
        type='checkbox'
        name={props.label}
        value={props.value}
        defaultChecked={props.value} /* eslint-disable-line react/no-unknown-property */
        onChange={(e) => props.onChange(e.target.checked)}
      />
      <span className={styles.checkboxLabel}>{props.label}</span>
    </label>
  )
}

const Input = (props: InputProps) => {
  return (
    <div>
      <label>{props.label}{props.required && <font color='red'> *</font>}</label>
      <input
        type={props.type || 'text'}
        name={props.label}
        value={props.value}
        placeholder={props.placeholder}
        onChange={(e) => props.onChange(e.target.value)}
      />
    </div>
  )
}

const BannerModal = (props: Props) => {
  const [banner, setBanner] = useState(props.account?.banner || '')
  const [bannerBg, setBannerBg] = useState(props.account?.bannerBg || '')
  const [url, setUrl] = useState(props.account?.url || '')
  const [title, setTitle] = useState(props.account?.title || '')
  const [username, setUsername] = useState(props.account?.username || '')
  const [password, setPassword] = useState(props.account?.password || '')
  const [hidden, setHidden] = useState(props.account?.hidden || false)
  const [bannerApplied, setBannerApplied] = useState(props.account?.banner || '')
  const [bannerBgApplied, setBannerBgApplied] = useState(props.account?.bannerBg || '')
  const [editing] = useState(props.account !== null)

  useEffect(() => {
    document.body.setAttribute(ATTR_MODAL_OPEN, true)

    return () => {
      document.body.removeAttribute(ATTR_MODAL_OPEN)
    }
  })

  const handleAdd = () => {
    const account = {
      title,
      url,
      banner,
      bannerBg,
      username,
      password,
      hidden: hidden ? 1 : 0
    }

    if (!editing) {
      post('/api/apps/', {
        body: JSON.stringify(account)
      }).then(response => {
        return response.json().then(data => ({
          ok: response.ok,
          status: response.status,
          body: data
        }))
      }).then(response => {
        if (response.ok) {
          props.onClose(true, response.body)
        } else {
          alert('Error.\n\nNote: \'Title\' and \'Url\' is required.')
        }
      }).catch(reason => {
        console.error(reason)
      })
    } else {
      if (!props.account) {
        throw new Error('\'props.account\' is not defined!')
      }

      // Add id to the object
      account.id = props.account.id

      put(`/api/apps/${account.id}`, {
        body: JSON.stringify(account)
      }).then(() => {
        props.onClose(true, account)
      }).catch(reason => {
        console.error(reason)
      })
    }
  }

  const handleBannerApply = () => {
    setBannerApplied(banner)
    setBannerBgApplied(bannerBg)
  }

  const handleCancel = () => {
    // Not needed I think
    // this.setState(this.defaultState())
    props.onClose(false, null)
  }

  return (
    <div className={styles.bannerModal} data-show={props.showModal}>
      <div className={styles.content}>
        <div className={styles.top}>
          <img
            className={styles.modalBanner}
            // If 'bannerApplied' is not defined use the "Tiniest Gif Ever" from here: http://probablyprogramming.com/2009/03/15/the-tiniest-gif-ever
            src={bannerApplied || 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='}
            style={{ backgroundColor: bannerApplied ? bannerBgApplied || 'unset' : '#808080' }}
          />
          <div className={styles.right}>
            <Input
              label='Banner'
              type='url'
              value={banner}
              placeholder='/banner.png'
              onChange={setBanner} />

            <Input
              label='Background Color'
              value={bannerBg}
              placeholder='#HEX'
              onChange={setBannerBg} />
            <button onClick={handleBannerApply}>Apply</button>
          </div>
        </div>

        <Input
          label='Name'
          value={title}
          onChange={setTitle}
          required />

        <Input
          label='URL'
          value={url}
          onChange={setUrl}
          required />

        <Input
          label='Username'
          value={username}
          onChange={setUsername} />

        <Input
          label='Password'
          value={password}
          // Disable LastPass autofill, since it messes with the styling
          data-lpignore
          onChange={setPassword} />

        <div style={{ position: 'relative', marginBottom: '12px' }}>
          <CheckBox
            label='Hidden'
            value={hidden}
            onChange={setHidden} />

          <font color='red' style={{ position: 'absolute', right: 0 }}>* Required information</font>
        </div>
        <div>
          <button onClick={handleAdd}>{editing ? 'Save' : 'Add'}</button>
          <button onClick={handleCancel}>Cancel</button>
        </div>
      </div>
    </div>
  )
}

export default BannerModal
