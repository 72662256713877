// @flow
import styles from './StatusLabel.css'

type Props = {
  children?: any,
  color: StatusLabelColors
}

export const StatusLabelColors = {
  Red: 'red',
  Green: 'green',
  Gray: 'gray'
}

export const StatusLabel = (props: Props) => (
  <font className={styles.statusLabel} color={props.color}>{props.children}</font>
)
