// @flow
import classnames from 'classnames'
import styles from './Dialer.css'

export type DialerEvent = {
  clear: boolean,
  backspace: boolean,
  digit: number
}

type Props = {
  className?: string,
  onDigitClick: (event: DialerEvent) => void
}

const digitRows = [
  [7, 8, 9],
  [4, 5, 6],
  [1, 2, 3],
  ['C', 0, '⌫']
]

export const Dialer = (props: Props) => {
  const handleDigitClick = (event: Event) => {
    const digit = event.currentTarget.dataset.digit

    props.onDigitClick({
      backspace: digit === '⌫',
      clear: digit === 'C',
      digit: parseInt(digit)
    })
  }

  return (
    <table className={classnames(styles.dialer, props.className)}>
      <tbody>
        {digitRows.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {row.map((digit, digitIndex) => (
              <td
                key={digitIndex}
                data-digit={digit}
                onClick={handleDigitClick}><span>{digit}</span></td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}
