// @flow
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import styles from './EditProfile.css'
import autobind from 'class-autobind'
import classnames from 'classnames'
import CardContainer from 'Components/CardContainer/CardContainer'
import { post, fdelete } from 'Shared/Common'
// $FlowFixMe
import { ProfileType } from 'Shared/Types'

type Props = {
  profile: ProfileType
}

type State = {
  avatarButton: boolean,
  backgroundButton: boolean,
  profile: ProfileType
}

class EditProfile extends PureComponent<Props, State> {
  avatar: any
  background: any

  constructor (props) {
    super(props)
    this.state = {
      avatarButton: false,
      backgroundButton: false,
      profile: this.props.profile
    }
    autobind(this)
  }

  handleInputChange (event) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    this.setState({
      profile: Object.assign({}, this.state.profile, {
        [name]: value
      })
    })
  }

  onUploadAvatar (e) {
    var data = new FormData()
    data.append('file', this.avatar.files[0])

    fetch('/api/profile/avatar', {
      method: 'POST',
      credentials: 'include',
      body: data
    })
      .then(response => response.json())
      .then(json => {
        this.avatar.value = null
        this.setState({
          profile: Object.assign({}, this.state.profile, {
            avatar: json.avatar,
            avatarMedium: json.avatarMedium
          }),
          avatarButton: false
        })
      })
  }

  onUploadBackground (e) {
    var data = new FormData()
    data.append('file', this.background.files[0])

    fetch('/api/profile/background', {
      method: 'POST',
      credentials: 'include',
      body: data
    })
      .then(response => response.json())
      .then(json => {
        this.background.value = null
        this.setState({
          profile: Object.assign({}, this.state.profile, {
            background: json.background
          }),
          backgroundButton: false
        })
        window.location.reload()
      })
  }

  handleClearAvatar (e) {
    fdelete('/api/profile/avatar').then(response => {
      if (response.ok) {
        this.avatar.value = null
        this.setState({
          profile: Object.assign({}, this.state.profile, {
            avatar: '',
            avatarMedium: ''
          })
        })
      }
    })
  }

  handleClearBackground (e) {
    fdelete('/api/profile/background').then(response => {
      if (response.ok) {
        this.background.value = null
        this.setState({
          profile: Object.assign({}, this.state.profile, {
            background: ''
          })
        })
      }
    })
  }

  render () {
    const { displayName,
      realName,
      avatar,
      avatarMedium,
      location,
      bio,
      steam,
      discord,
      background } = this.state.profile

    return (
      <CardContainer className={styles.editProfile} title='Edit profile'>
        <div>
          <div className={styles.row}>
            <span>Name</span>
            <input
              type='text'
              label='Name'
              name='displayName'
              value={displayName || ''}
              onChange={this.handleInputChange} />
          </div>
          <div className={styles.row}>
            <span>Real Name</span>
            <input
              type='text'
              label='Real Name'
              name='realName'
              value={realName || ''}
              onChange={this.handleInputChange} />
          </div>
          <div className={styles.row}>
            <span>Location</span>
            <input
              type='text'
              label='Location'
              name='location'
              value={location}
              onChange={this.handleInputChange} />
          </div>
          <div className={classnames(styles.row, styles.bioRow)}>
            <span>Bio</span>
            <textarea
              label='Bio'
              name='bio'
              value={bio || ''}
              onChange={this.handleInputChange} />
          </div>
          <div className={styles.row}>
            <span>Steam</span>
            <input
              type='text'
              label='Steam'
              name='steam'
              value={steam || ''}
              onChange={this.handleInputChange} />
          </div>
          <div className={styles.row}>
            <span>Discord</span>
            <input
              type='text'
              label='Discord'
              name='discord'
              value={discord || ''}
              onChange={this.handleInputChange} />
          </div>
          <button onClick={(e) => {
            const { displayName, realName, location, bio, steam, discord } = this.state.profile
            post('/api/profile', {
              body: JSON.stringify({
                displayName,
                realName,
                location,
                bio,
                steam,
                discord
              })
            })
              .then(response => {
                if (response.ok) {
                  alert('Saved')
                } else if (response.status === 400) {
                  return response.text().then(text => alert(text))
                } else {
                  alert('Server responded: ' + response.status)
                }
              })
              .catch(error => {
                alert('Error: ' + error)
              })
          }}>Save</button>
        </div>
        <div className={styles.avatarContainer}>
          <h3>Avatar</h3>
          <div className={styles.avatarVariations}>
            <div>
              <img width='184' height='184' src={avatar || '/public/avatar_default.jpg'} />
              <div>184px</div>
            </div>
            <div>
              <img width='64' height='64' src={avatarMedium || '/public/avatar_default.jpg'} />
              <div>64px</div>
            </div>
            <div>
              <img width='32' height='32' src={avatarMedium || '/public/avatar_default.jpg'} />
              <div>32px</div>
            </div>
          </div>
          <div className={styles.avatarUpload}>
            <input
              type='file'
              accept='image/png, image/jpg, image/jpeg, .gif'
              ref={(input) => { this.avatar = input }}
              onChange={(e) => {
                this.setState({
                  avatarButton: true
                })
              }} />
            <button
              disabled={!this.state.avatarButton}
              onClick={this.onUploadAvatar}>Upload</button>
          </div>
          <button className={styles.clearButton} onClick={this.handleClearAvatar}>Clear</button>
        </div>
        <div className={classnames(styles.avatarContainer, styles.background)}>
          <h3>Background</h3>
          <div style={{ marginBottom: '8px' }}>Tip: Steam backgrounds work well. Otherwise any background that fades out to black.</div>
          <img src={background} />
          <div className={styles.avatarUpload}>
            <input
              type='file'
              accept='image/png, image/jpg, image/jpeg'
              ref={(input) => { this.background = input }}
              onChange={(e) => {
                this.setState({
                  backgroundButton: true
                })
              }} />
            <button
              disabled={!this.state.backgroundButton}
              onClick={this.onUploadBackground}>Upload</button>
          </div>
          <button className={styles.clearButton} onClick={this.handleClearBackground}>Clear</button>
        </div>
      </CardContainer>
    )
  }
}

const mapStateToProps = state => {
  const { profile } = state
  return {
    profile
  }
}

const EditProfileExport = connect(
  mapStateToProps
)(EditProfile)

export default EditProfileExport
