// @flow
import CardContainer from 'Components/CardContainer/CardContainer'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import styles from './RecoverPasswordPage.css'

type ReduxState = {
  exists: boolean
}

const RecoverPasswordPage = () => {
  const exists = useSelector((state: ReduxState) => state)
  const [errors, setErrors] = useState([])
  const [password, setPassword] = useState('')
  const [passwordRepeat, setPasswordRepeat] = useState('')
  const params = useParams()

  const handleSubmit = (event: Event) => {
    event.preventDefault()
    const token = params.token

    // Check if any input is null
    if (!password || !passwordRepeat) {
      setErrors(['All fields needs to be filled.'])
    } else if (password !== passwordRepeat) {
      setErrors(['Passwords doesn\'t match!'])
    } else {
      fetch('/api/users/recover/' + token, {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify({
          password,
          passwordRepeat
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => {
        if (response.ok || response.status === 500) {
          return response
        } else {
          return response.json().then(json => ({
            ok: response.ok,
            status: response.status,
            body: json
          }))
        }
      }).then(response => {
        if (response.ok) {
          // Success, navigate to home
          window.location = '/'
        } else if (response.status === 403) {
          alert(response.body.message)
          setErrors([])
        } else if (response.status === 422) {
          // Show errors to user
          setErrors(Object.values(response.body.errors).map((err: any) => err.msg))
        } else {
          throw new Error('Unknown error. Check console.')
        }
      }).catch(error => {
        alert(error)
        console.error(error)
      })
    }
  }

  return (
    <CardContainer className={styles.recoverPasswordPage}>
      <h2>Recover Account</h2>
      {!exists &&
        <div className={styles.tokenError}>Expired or invalid token!</div>
      }
      {errors && errors.length > 0 &&
        <section className={styles.errors}>
          <ul>
            {errors.map((err, i) => (
              <li key={i}>{err}</li>
            ))}
          </ul>
        </section>
      }
      {exists &&
        <form onSubmit={handleSubmit}>
          <div>
            <label>Password</label>
            <input
              autoComplete='new-password'
              type='password'
              label='Password'
              name='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)} />
          </div>
          <div>
            <label>Repeat</label>
            <input
              autoComplete='new-password'
              type='password'
              label='Repeat'
              name='passwordRepeat'
              value={passwordRepeat}
              onChange={(e) => setPasswordRepeat(e.target.value)} />
          </div>
          <div>
            <input type='submit' value='Change' />
            <button className={styles.backButton} type='button'>
              <a href='/recover'><span>Back</span></a>
            </button>
          </div>
        </form>
      }
    </CardContainer>
  )
}

export default RecoverPasswordPage
