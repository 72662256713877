// @flow
import React, { PureComponent } from 'react'
import { Route, Routes } from 'react-router-dom'

import styles from './App.css' // eslint-disable-line

import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'
import Home from './components/Home/Home'
import Admin from './pages/AdminTools/Admin'
import Maintenance from './pages/AdminTools/Maintenance'
import Reports from './pages/AdminTools/Reports'
import Stats from './pages/AdminTools/Stats'
import Countdown from './components/Countdown/Countdown'
import FriendsPage from './pages/FriendsPage/FriendsPage'
import GameRequests from './components/GameRequests/GameRequests'
import Music from './components/Music/Music'
import Plex from './components/Plex/Plex'
import ServicePage from './pages/Services/ServicePage'
import Services from './components/Services/Services'
import YouTubeDl from './components/YouTubeDl/YouTubeDl'
import Login from './components/Users/Login/Login'
import Register from './components/Users/Register/Register'
import UserProfile from './pages/UserProfile/UserProfile'
import EditProfile from './pages/UserProfile/EditProfile'
import Settings from './pages/Settings/Settings'
import RecoverPage from './pages/RecoverPage/RecoverPage'
import RecoverPasswordPage from './pages/RecoverPage/RecoverPasswordPage'
import ArticlePage from './pages/ArticlePage/ArticlePage'
import ArticlesPage from './pages/ArticlesPage/ArticlesPage'
import ArticleWriterPage from './pages/ArticleWriterPage/ArticleWriterPage'
import Search from './pages/Search/Search'

type Props = {
  version: string
}

export default class App extends PureComponent<Props, null> {
  render() {
    return (
      <div style={{ paddingBottom: '80px' }}>
        <Header />
        <Routes className={styles.contentWrapper}>
          <Route exact path='/' element={<Home />} />
          <Route exact path='/admin' element={<Admin />} />
          <Route exact path='/admin/maintenance' element={<Maintenance />} />
          <Route exact path='/admin/reports' element={<Reports />} />
          <Route exact path='/admin/stats' element={<Stats />} />
          <Route exact path='/admin/stats/:ip_address' element={<Stats />} />
          <Route exact path='/articles' element={<ArticlesPage />} />
          <Route exact path='/articles/write' element={<ArticleWriterPage />} />
          <Route exact path='/article/:article_url' element={<ArticlePage />} />
          <Route path='/countdown' element={<Countdown />} />
          <Route path='/friends' element={<FriendsPage />} />
          <Route path='/game_requests' element={<GameRequests />} />
          <Route exact path='/services' element={<Services />} />
          <Route exact path='/services/:id' element={<ServicePage />} />
          <Route path='/login' element={<Login />} />
          <Route path='/music' element={<Music />} />
          <Route path='/plex' element={<Plex />} />
          <Route path='/youtube-dl' element={<YouTubeDl />} />
          <Route path='/register' element={<Register />} />
          <Route exact path='/users/:id' element={<UserProfile />} />
          <Route path='/profile/edit' element={<EditProfile />} />
          <Route path='/profile/settings' element={<Settings />} />
          <Route exact path='/recover' element={<RecoverPage />} />
          <Route exact path='/recover/:token' element={<RecoverPasswordPage />} />
          <Route exact path='/search' element={<Search />} />
        </Routes>
        <Footer version={this.props.version} />
      </div>
    )
  }
}
