// @flow

/**
 * Sends a `DELETE` request to given URL with credentials included and `Content-Type` as JSON.
 * @param {*} input The URL to use.
 * @param {*} init  An `Object` containing any custom settings to apply to the request.
 *                  See the documentation for the [Fetch API](https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch#Parameters) for options.
 */
export function fdelete (input: Request | string, init?: Object): Promise<Response> {
  return fetch(input, Object.assign({}, init, {
    credentials: 'include',
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    }
  }))
}

/**
 * Sends a `GET` request to given URL with credentials included and `Content-Type` as JSON.
 * @param {*} input The URL to use.
 * @param {*} init  An `Object` containing any custom settings to apply to the request.
 *                  See the documentation for the [Fetch API](https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch#Parameters) for options.
 */
export function get (input: Request | string, init?: Object): Promise<Response> {
  return fetch(input, Object.assign({}, init, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  }))
}

/**
 * Sends a `POST` request to given URL with credentials included and `Content-Type` as JSON.
 * @param {*} input The URL to use.
 * @param {*} init  An `Object` containing any custom settings to apply to the request.
 *                  See the documentation for the [Fetch API](https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch#Parameters) for options.
 */
export function post (input: Request | string, init?: Object): Promise<Response> {
  return fetch(input, Object.assign({}, init, {
    credentials: 'include',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    }
  }))
}

/**
 * Sends a `PUT` request to given URL with credentials included and `Content-Type` as JSON.
 * @param {*} input The URL to use.
 * @param {*} init  An `Object` containing any custom settings to apply to the request.
 *                  See the documentation for the [Fetch API](https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch#Parameters) for options.
 */
export function put (input: Request | string, init?: Object): Promise<Response> {
  return fetch(input, Object.assign({}, init, {
    credentials: 'include',
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    }
  }))
}

/**
 * Tries to parse `value` as `boolean`.
 * @param {*} value The `string` or `number` to parse.
 */
export function parseBoolean (value: string | number): boolean {
  if (typeof value === 'string') {
    value = value.toLowerCase()

    if (value === 'true' || value === 'false') {
      return JSON.parse(value)
    } else if (value === '1' || value === '0') {
      return !!parseInt(value)
    } else {
      throw new Error('Invalid value.')
    }
  } else if (typeof value === 'number') {
    if (value < 2 && value > -1) {
      return !!value
    } else {
      throw new Error('Invalid value.')
    }
  } else if (typeof value === 'boolean') {
    return value
  } else {
    throw new Error('Unsupported value type.')
  }
}

export function humanFileSize (bytes: number, si: boolean) {
  var thresh = si ? 1000 : 1024
  if (Math.abs(bytes) < thresh) {
    return bytes + ' B'
  }
  var units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
  var u = -1
  do {
    bytes /= thresh
    ++u
  } while (Math.abs(bytes) >= thresh && u < units.length - 1)
  return bytes.toFixed(1) + ' ' + units[u]
}
