// @flow
import CardContainer from 'Components/CardContainer/CardContainer'
import moment from 'moment'
import { useEffect, useReducer, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import styles from './Stats.css'
import { post } from 'Shared/Common'

type AccessLogEntry = {
  method: string,
  ip: string,
  url: string,
  date: Date,
  user_agent: string
}

type ReduxState = {
  accessLogs: AccessLogEntry[]
}

const Stats = () => {
  const ipLocationsRef = useRef()
  const { accessLogs, totalCount } = useSelector((state: ReduxState) => state)
  const [page, setPage] = useState(1)
  const [jumpDialogLeft, setJumpDialogLeft] = useState(0)
  const [jumpDialogTop, setJumpDialogTop] = useState(0)
  const [jumpDialogShown, setJumpDialogShown] = useState(false)
  const [showAdBlockError, setShowAdBlockError] = useState(false)
  const [, forceUpdate] = useReducer(x => x + 1, 0)

  useEffect(() => {
    // Get IP locations and cache it
    const ips = [...new Set(accessLogs.map(x => x.ip))]

    post('/api/admin/ip-info', {
      body: JSON.stringify(ips)
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          setShowAdBlockError(true)
          return
        }

        ipLocationsRef.current = response
        forceUpdate()
      })
      .catch(error => {
        if (error instanceof TypeError) { // Failed to fetch?
          setShowAdBlockError(true)
        } else {
          console.error(error)
        }
      })

    document.addEventListener('click', (event) => {
      const jumpDialog = document.getElementById('jumpdialog')
      const jumpButton = document.getElementById('jumpbutton')
      let targetElement = event.target

      do {
        if (targetElement == jumpDialog || targetElement == jumpButton) {
          return
        }

        targetElement = targetElement.parentNode
      } while (targetElement)

      setJumpDialogShown(false)
    })
  }, [])

  const getFlag = (ip: string) => {
    const flag = ipLocationsRef.current.find(x => x.query == ip)

    if (!flag) {
      return null
    }

    return (
      <>
        <div>
          <img
            src={`https://www.flagcdn.com/16x12/${flag.countryCode?.toLowerCase()}.png`}
            title={flag.country}
          />
        </div>
        <div>
          <a href={'/admin/stats/' + ip}>{ip}</a>
          <div title={flag.org}>{flag.org}</div>
        </div>
      </>)
  }

  const getPagesButtons = () => {
    const logCount = totalCount || 0
    const pages = Math.ceil(logCount / 100)
    const pageButtons = []
    let newPage = page

    // Check if user selected page is valid, if not default to page 1
    if (newPage < 1 || newPage > pages) {
      newPage = 1
    }

    let pagesLeftIndex = 1
    let pagesRightIndex = pages

    // If there are more than 9 pages, limit shown to 9
    if (pages > 9) {
      pagesLeftIndex = Math.max(1, newPage - 4)
      pagesRightIndex = Math.min(pages, newPage + 4)

      // Check if we have 9 pages
      while (pagesRightIndex - pagesLeftIndex < 9) {
        if (pagesLeftIndex - 1 > 0) {
          pagesLeftIndex--
          continue
        }
        if (pagesRightIndex + 1 <= pages) {
          pagesRightIndex++
          continue
        }
      }
    }

    for (let i = pagesLeftIndex; i <= pagesRightIndex; i++) {
      pageButtons.push(
        <span className={styles.paginationPage} onClick={handlePageButtonClick} key={i} disabled={page === i}>{i}</span>
      )
    }

    return (
      <div className={styles.pagination}>
        <span>
          {pageButtons}
        </span>
        <span>
          <span className={styles.paginationPage} id='jumpbutton' onClick={handleJumpButton}>Jump</span>
        </span>
      </div>
    )
  }

  const handlePageButtonClick = (event: Event) => {
    event.preventDefault()
    setPage(parseInt(event.target.innerText))
  }

  const handleJumpButton = (event: Event) => {
    event.preventDefault()
    setJumpDialogLeft(event.target.offsetLeft)
    setJumpDialogTop(event.target.offsetTop + event.target.offsetHeight)
    setJumpDialogShown(!jumpDialogShown)
  }

  const logCount = totalCount || 0

  return (
    <>
      <CardContainer className={styles.stats} title='Stats' wide>
        There are {logCount} entries.
      </CardContainer>

      <CardContainer className={styles.entries} title='Entries' wide>
        {showAdBlockError &&
          <div className={styles.failedToFetch}>
            <div>Failed to fetch information about IPs. AdBlock could be blocking calls.</div>
          </div>
        }

        {getPagesButtons()}

        <table className={styles.statsTable} cellSpacing='0'>
          <thead>
            <tr>
              {/* eslint-disable react/no-unknown-property */}
              <th align='left'>Method</th>
              <th align='left'>IP</th>
              <th align='left'>URL</th>
              <th align='left'>Date</th>
              {/* eslint-enable react/no-unknown-property */}
            </tr>
          </thead>
          <tbody>
            {accessLogs.map((x, i) =>
              <tr key={i}>
                <td className={styles.method}>
                  <span data-method={x.method.toLowerCase()}>{x.method}</span>
                </td>
                <td className={styles.ip}>
                  {ipLocationsRef.current && getFlag(x.ip)}
                  {!ipLocationsRef.current && <a href={'/admin/stats/' + x.ip}>{x.ip}</a>}
                </td>
                <td className={styles.url}>
                  <div>{x.url}</div>
                  <div>{x.user_agent}</div>
                </td>
                <td title={x.date.toString()}>{moment(x.date).locale('nb').format('L LTS')}</td>
              </tr>
            )}
          </tbody>
        </table>
      </CardContainer>

      <div className={styles.statsJumpDialog} id='jumpdialog' style={{
        top: jumpDialogTop + 'px',
        left: jumpDialogLeft + 'px',
        display: jumpDialogShown ? 'block' : 'none'
      }}>
        <div>
          <input id='jumpto' min={1} max={Math.ceil(logCount / 100)} type='number' defaultValue={1} />
        </div>
        <div>
          <button onClick={() => {
            const jumpto = document.getElementById('jumpto')
            setPage(parseInt(jumpto.value || 1))
            setJumpDialogShown(false)
          }}>Jump</button>
        </div>
      </div>
    </>
  )
}

export default Stats
