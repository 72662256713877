// @flow
import { useEffect, useRef, useState } from 'react'
import styles from './ArticleEditor.css'

type Props = {
  onChange: (value: string) => void
}

const ArticleEditor = (props: Props) => {
  const textAreaRef = useRef()
  // Img
  const imgUrlRef = useRef()
  // Link
  const linkDisplayTextRef = useRef()
  const linkLinkRef = useRef()
  const [showModalImg, setShowModalImg] = useState(false)
  const [showModalLink, setShowModalLink] = useState(false)

  const encloseTextWith = (start: string, end: string) => {
    const textarea = textAreaRef.current

    textarea.setRangeText(
      start + textarea.value.slice(textarea.selectionStart, textarea.selectionEnd) + end,
      textarea.selectionStart,
      textarea.selectionEnd,
      'select')
    textarea.focus()
    textarea.setSelectionRange(textarea.selectionStart + start.length, textarea.selectionEnd - end.length)
  }

  const handleBold = () => encloseTextWith('[b]', '[/b]')

  const handleImg = () => {
    setShowModalImg(true)
    setShowModalLink(false)
  }

  const handleImgInsert = () => {
    const textarea = textAreaRef.current
    const url = imgUrlRef.current

    textarea.setRangeText(
      '[img=' + url.value + ']',
      textarea.selectionStart,
      textarea.selectionStart,
      'select')
    textarea.focus()

    setShowModalImg(false)
  }

  const handleLink = () => {
    setShowModalImg(false)
    setShowModalLink(true)
  }

  const handleLinkInsert = () => {
    const textarea = textAreaRef.current
    const displayText = linkDisplayTextRef.current
    const link = linkLinkRef.current

    textarea.setRangeText(
      '[url=' + link.value + ']' + displayText.value + '[/url]',
      textarea.selectionStart,
      textarea.selectionStart,
      'select')
    textarea.focus()

    setShowModalLink(false)
  }

  const handleChange = () => {
    props?.onChange(textAreaRef.current.value)
  }

  useEffect(() => {
    if (showModalImg) {
      imgUrlRef.current.focus()
    }

    if (showModalLink) {
      linkDisplayTextRef.current.focus()
    }
  }, [showModalImg, showModalLink])

  return (
    <div className={styles.articleEditor}>
      <div className={styles.toolbar}>
        <button data-nostyling onClick={handleBold}>B</button>
        <button data-nostyling onClick={() => encloseTextWith('[i]', '[/i]')}>i</button>
        <button data-nostyling onClick={handleImg}>img</button>
        <button data-nostyling onClick={handleLink}>link</button>
      </div>
      <textarea ref={textAreaRef} onChange={handleChange} />

      {(showModalImg || showModalLink) &&
        <div className={styles.modalOverlay} />}

      {showModalImg &&
        <div className={styles.modal}>
          <div className={styles.modalTop}>
            Insert Image
          </div>
          <div className={styles.modalContent}>
            <input type='url' placeholder='Image Link' ref={imgUrlRef} />
          </div>
          <div className={styles.modalBottom}>
            <button onClick={handleImgInsert}>Insert</button>
            <button onClick={() => setShowModalImg(false)}>Cancel</button>
          </div>
        </div>}

      {showModalLink &&
        <div className={styles.modal}>
          <div className={styles.modalTop}>
            Insert Link
          </div>
          <div className={styles.modalContent}>
            <input type='text' placeholder='Display Text' ref={linkDisplayTextRef} />
            <input type='url' placeholder='Link' ref={linkLinkRef} />
          </div>
          <div className={styles.modalBottom}>
            <button onClick={handleLinkInsert}>Insert</button>
            <button onClick={() => setShowModalLink(false)}>Cancel</button>
          </div>
        </div>}
    </div>
  )
}

export default ArticleEditor
