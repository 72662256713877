// @flow
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import styles from './UserProfile.css'
import autobind from 'class-autobind'
import CardContainer from 'Components/CardContainer/CardContainer'
import Comments from 'Components/Comments/Comments'
import { post, fdelete } from 'Shared/Common'
// $FlowFixMe
import { FriendType, ProfileType, UserType } from 'Shared/Types'

type Props = {
  user: UserType,
  profile: ProfileType,
  friends: FriendType[],
  pending: FriendType[]
}

type State = {
  profile: ProfileType
}

class UserProfile extends PureComponent<Props, State> {
  constructor (props) {
    super(props)

    this.state = {
      profile: this.props.profile
    }
    autobind(this)
  }

  addFriend () { // ToDo
    post(`/api/users/friends/${this.state.profile.id}`).then(result => {
      switch (result.status) {
        case 200:
          document.location.reload()
          break
        case 400:
          alert('Already friends')
          break
        case 404:
          alert('User not found')
          break
        default:
          alert('Unknown result: ' + result.status)
          break
      }
    })
  }

  removeFriend () {
    fdelete(`/api/users/friends/${this.state.profile.id}`).then(result => {
      switch (result.status) {
        case 200:
          document.location.reload()
          break
        case 400:
          alert('Not friends')
          break
        default:
          break
      }
    })
  }

  format (text: string): string {
    return text ? text.replace(/\[url=(.*?)\](.*?)\[\/url\]/gi, '<a href="$1">$2</a>') : ''
  }

  render () {
    const { profile } = this.state
    const { user, friends, pending } = this.props
    const avatarImg = profile.avatar || '/public/avatar_default.jpg'
    const areFriends = friends.some(x => user && x.id === user.id)

    return (
      <CardContainer className={styles.userProfile}>
        <div className={styles.profileHeader}>
          <img className={styles.avatar} src={avatarImg} />
          <div style={{ position: 'relative' }}>
            <h2 className={styles.displayName}>{profile.displayName}</h2>
            <span className={styles.realName}>{profile.realName}</span> - <span className={styles.location}>{profile.location}</span>
            <div className={styles.menu}>
              {user && user.id === profile.id && // Options for the user
                <ul>
                  <a href='/profile/edit'>Edit</a>
                  <a href='/profile/settings'>Settings</a>
                </ul>
              }
              {user && user.id !== profile.id && // Options for other users
                <ul>
                  {!areFriends &&
                    <button data-nostyling onClick={this.addFriend}>Add Friend</button>
                  }
                  {areFriends &&
                    <button data-nostyling onClick={this.removeFriend}>Remove Friend</button>
                  }
                  <button data-nostyling><strike>Block</strike></button>
                  <button data-nostyling><strike>Report</strike></button>
                </ul>
              }
            </div>
          </div>
          <div className={styles.clearleft} />
        </div>
        <div className={styles.profileBody} >
          <div className={styles.profileBio}>
            <h4>Bio</h4>
            <span className={styles.bio} dangerouslySetInnerHTML={{ __html: this.format(profile.bio) }} />
          </div>
          <div className={styles.links}>
            <h3>Links</h3>
            {profile.steam &&
              <div className={styles.linkRow}>
                <a className={styles.link} href={`https://steamcommunity.com/id/${profile.steam}`} />
                <img className={styles.linkImg} src='/public/steam.png' />
                <span className={styles.linkTitle}>{profile.steam}</span>
              </div>}

            {profile.discord &&
              <div className={styles.linkRow}>
                <img className={styles.linkImg} src='/public/discord.png' />
                <span className={styles.linkTitle}>{profile.discord}</span>
              </div>}

            <div className={styles.friendsHeader}>
              <h3>
                Friends
                {pending.length > 0 &&
                  <div>{`(${pending.length} pending)`}</div>}
              </h3>
              <a href='/friends'>View all</a>
            </div>

            <ul className={styles.friendsList}>
              {friends.map((friend, index) =>
                <li key={index}>
                  <a href={'./' + friend.id}><img src={friend.avatarMedium || '/public/avatar_default.jpg'} />{friend.displayName}</a>
                </li>)}
            </ul>
            <div className={styles.clearleft} />
          </div>
        </div>
        <Comments userId={profile.id} />
      </CardContainer>
    )
  }
}

const mapStateToProps = state => {
  const { user, profile, friends, pending } = state
  return {
    user,
    profile,
    friends,
    pending
  }
}

const UserProfileExport = connect(
  mapStateToProps
)(UserProfile)

export default UserProfileExport
