// @flow
import classnames from 'classnames'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { post } from 'Shared/Common'
import styles from './Header.css'
import HeaderItem from './HeaderItem'
import { useLocation, useNavigate } from 'react-router-dom'
// $FlowFixMe
import { UserType } from 'Shared/Types'

type Props = {
  location: any,
  loggedIn: boolean,
  user: UserType
}

type State = {
  menuOpen: boolean
}

const Header = (props: Props) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [menuOpen, setMenuOpen] = useState(false)
  const [loggedIn, user] = useSelector((state) => [state.loggedIn, state.user])

  const handleLogout = (event) => {
    event.preventDefault()
    post('/api/users/logout').then(response => {
      if (response.ok) {
        // Success, navigate to home and reload
        navigate('/')
        navigate(0)
      } else {
        throw new Error('Unknown error. Check console')
      }
    }).catch(error => {
      alert(error)
      console.error(error)
    })
  }

  const handleMenuClick = () => setMenuOpen(!menuOpen)

  const pages = {
    '/': 'Home',
    '/countdown': 'Countdown',
    '/game_requests': 'Game Requests',
    '/services': 'Services',
    '/music': 'Music',
    '/plex': 'Plex',
    '/youtube-dl': 'youtube-dl'
  }
  const isSelfProfile = location.pathname.replace(/(.+)\/$/g, '$1') === '/users/' + (user ? user.id : '')

  return (
    <header className={styles.header}>
      <div className={styles.headerMenu}>
        <img onClick={handleMenuClick} src='/public/menu.svg' />
      </div>
      <div className={styles.headerUser}>
        <a className={styles.search} href='/search'>Search</a>
        <span className={styles.searchDivider} />
        {loggedIn ? (
          <>
            <a className={classnames({ [styles.active]: isSelfProfile })} href={'/users/' + user.id}>
              {user.displayName}
            </a>
            <a className={styles.logoutButton} href='/logout' onClick={handleLogout}>
              Logout
            </a>
          </>
        ) : (
          <a href='/login'>Login</a>
        )}
      </div>
      <ul className={classnames(styles.headerList, { [styles.open]: menuOpen })}>
        {loggedIn && user.role >= 100 &&
          <HeaderItem key={-1} href={'/admin'} title='Admin' location={location.pathname} />
        }
        {Object.keys(pages).map((key, index) =>
          <HeaderItem key={index} href={key} title={pages[key]} location={location.pathname} />
        )}
      </ul>
    </header>
  )
}

export default Header
