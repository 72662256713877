// @flow
import React, { PureComponent } from 'react'
import styles from './HeaderItem.css'
import classnames from 'classnames'

type Props = {
  href: string,
  title: string,
  location: string
}

export default class HeaderItem extends PureComponent<Props, void> {
  render () {
    const { href, title } = this.props
    const location = this.props.location.replace(/(.+)\/$/g, '$1')

    return (
      <li className={styles.headerItem}>
        <a className={classnames({ [styles.active]: location === href })}
          href={href}>{title}</a>
      </li>
    )
  }
}
