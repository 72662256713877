// @flow
import styles from './TimeList.css'

type Props = {
  title: string,
  times: string[],
  onListClick: (event: any) => void
}

const TimeList = (props: Props) => {
  const { title, times, onListClick } = props

  return (
    <ul className={styles.timeList}>
      <b>{title}</b>
      {times.map((time, index) => {
        return (
          <li key={index} data-time={time} onClick={onListClick}>{time}</li>
        )
      })}
    </ul>
  )
}

export default TimeList
