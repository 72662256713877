// @flow
import classnames from 'classnames'
import { ReactSVG } from 'react-svg'
import styles from './Banner.css'
// $FlowFixMe
import { AccountType } from 'Shared/Types'

type Props = {
  account: AccountType,
  userHasAccess: boolean,
  editing: boolean,
  onEdit: () => void,
  onDelete: () => void
}

const Banner = (props: Props) => {
  const { account, userHasAccess, editing, onEdit, onDelete } = props
  const bannerBg = account.banner ? account.bannerBg || 'unset' : '#808080'

  return (
    <li className={styles.banner} data-editing={editing}>
      <a className={styles.bannerLink} href={editing ? null : account.url}>
        <img
          className={styles.bannerImage}
          title={account.title}
          // If src is not defined use the "Tiniest Gif Ever" from here: http://probablyprogramming.com/2009/03/15/the-tiniest-gif-ever
          src={account.banner || 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='}
          style={{ backgroundColor: bannerBg, objectFit: account.banner ? 'cover' : 'contain', opacity: account.hidden ? '0.5' : '1' }}
        />
        <ReactSVG
          src='/public/icons/hidden.svg'
          className={classnames(styles.hiddenIconWrapper, { [styles.hidden]: account.hidden })}
        />
      </a>

      <span className={styles.bannerInfo}>
        <h2 className={styles.title}>{account.title}</h2>
        {userHasAccess &&
          <div>
            <div className={styles.username} style={{ opacity: account.username ? 1 : 0 }}>
              <span>Username: </span>
              <span>{account.username}</span>
            </div>
            <div className={styles.password} style={{ opacity: account.password ? 1 : 0 }}>
              <span>Password: </span>
              <span className={styles.passwordText}>{account.password}</span>
            </div>
          </div>}
      </span>

      {editing &&
        <div className={styles.bannerTooltip}>
          <div>
            <button data-nostyling onClick={onEdit}>
              <ReactSVG
                className={styles.editIconWrapper}
                src='/public/edit.svg'
              />
            </button>
            <button data-nostyling onClick={onDelete}>
              <ReactSVG
                className={styles.deleteIconWrapper}
                src='/public/rubbish-bin.svg'
              />
            </button>
          </div>
        </div>}
    </li>
  )
}

export default Banner
