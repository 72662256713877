// @flow
import CardContainer from 'Components/CardContainer/CardContainer'
import moment from 'moment'
import { useState } from 'react'
import { get } from 'Shared/Common'
import styles from './Maintenance.css'

const Maintenance = () => {
  const [actionLog, setActionLog] = useState([])

  const handleCleanupAvatars = () => {
    get('/api/admin/cleanup/avatars?simulate=true')
      .then(response => response.json())
      .then(response => {
        setActionLog(actionLog.slice().unshift({ ...response, date: Date.now() }))
      })
  }

  const handleCleanupBackgrounds = () => {
    get('/api/admin/cleanup/backgrounds?simulate=true')
      .then(response => response.json())
      .then(response => {
        setActionLog(actionLog.slice().unshift({ ...response, date: Date.now() }))
      })
  }

  <CardContainer className={styles.maintenance} title='Maintenance'>
    <div>
      <button onClick={handleCleanupAvatars}>Cleanup Avatars</button>
      <button onClick={handleCleanupBackgrounds}>Cleanup Backgrounds</button>
    </div>

    <ul>
      {actionLog.map((item, i) =>
        <li index={i} key={i}> {/* eslint-disable-line react/no-unknown-property */}
          <div>
            <span>Count: {item.count}, deleted: {item.deleted}, failed: {item.failed}</span>
            <span>{moment(item.date).format('L LTS')}</span>
          </div>
        </li>
      )}
    </ul>
  </CardContainer>
}

export default Maintenance
