// @flow
/* eslint-disable no-control-regex */
import CardContainer from 'Components/CardContainer/CardContainer'
import { StatusLabel, StatusLabelColors } from 'Components/StatusLabel/StatusLabel'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import Switch from 'react-switch'
import { post } from 'Shared/Common'
import styles from './ServicePage.css'
// $FlowFixMe
import { useLocation } from 'react-router-dom'

const UPDATE_MS = 3000

const formatStatus = (status: number) => {
  switch (status) {
    case 0:
      return <StatusLabel color={StatusLabelColors.Red}>Off</StatusLabel>
    case 1:
      return <StatusLabel color={StatusLabelColors.Green}>On</StatusLabel>
    case 2: // No-service-file
    case 3: // Unknown-status
    default: // Error-default
      return <StatusLabel color={StatusLabelColors.Gray}>Error</StatusLabel>
  }
}

const statusDescription = (status: number) => {
  switch (status) {
    case 0:
      return 'Not running'
    case 1:
      return 'Running'
    case 2:
      return 'Service file not found'
    case 3:
      return 'Unknown status'
    default:
      return `Unknown status (${status})`
  }
}

const ServicePage = () => {
  const textLog = useRef(null)
  const [initialService, user] = useSelector((state) => [state.service, state.user])
  const [autoRefresh, setAutoRefresh] = useState(true)
  const [service, setService] = useState(initialService)
  const [error, setError] = useState('')
  const [log, setLog] = useState('')
  const [sendingCommand, setSendingCommand] = useState(false)
  const [serviceUpdating, setServiceUpdating] = useState(false)
  const [serviceUpdatingStatus, setServiceUpdatingStatus] = useState(-1)
  const [scrollToBottom, triggerScrollToBottom] = useState(false)
  const location = useLocation()

  const formatLogs = (data: string) =>
    data.replace(/[\u001b\u009b][[()#;?]*(?:[0-9]{1,4}(?:;[0-9]{0,4})*)?[0-9A-ORZcf-nqry=><]/g, '')

  useEffect(() => {
    const service = location.pathname.split('/').slice(-1)[0]

    // Already have this on initial load so wait 2000ms before checking for first update
    setTimeout(() => checkForUpdates(), 2000)

    // Check that the user has access to logs
    if (user && user.role >= 2) {
      getLogs(service)
    }
  }, [])

  useEffect(() => {
    if (!scrollToBottom)
      return

    textLog.current.scrollTop = textLog.current.scrollHeight
    triggerScrollToBottom(false)
  }, [scrollToBottom])

  const checkForUpdates = () => {
    fetch(`/api/services/${service.id}`)
      .then(response => response.json())
      .then(json => setService(json))

    setTimeout(() => checkForUpdates(), 2000)
  }

  const getLogs = (service: string) => {
    fetch(`/api/services/logs?service=${service}`)
      .then(response => {
        if (!autoRefresh) {
          return
        }

        switch (response.status) {
          case 200:
          case 404:
          case 500:
            handleLogsResponse(response)
            break
          case 401:
            // Unauthorized
            break
          default:
            console.error(response)
        }
      })
      .catch(reason => console.error(reason))
      .finally(() => {
        if (autoRefresh) {
          setTimeout(() => getLogs(service), UPDATE_MS)
        }
      })
  }

  const handleAutoRefreshChange = (checked: boolean) => {
    if (checked) {
      const service = location.pathname.split('/').slice(-1)[0]
      getLogs(service)
    }

    setAutoRefresh(checked)
  }

  const handleLogsResponse = (response: Express.Response) => {
    response.json()
      .then(json => ({
        status: json.status ? json.status : response.status,
        data: json.data ? json.data : json.message
      }))
      .then(response => {
        if (response.status === 200) {
          // If text is scrolled to bottom, keep scrolling with new updates. Check before changing text
          const shouldScrollToBottom =
            textLog.current.scrollTop === (textLog.current.scrollHeight - textLog.current.clientHeight)

          setLog(new Date().toString() + formatLogs(response.data))
          triggerScrollToBottom(shouldScrollToBottom)
        } else {
          setError(response.data)
        }
      })
      .catch(reason => console.error(reason))
  }

  const sendCommand = (cmd: number) => {
    setSendingCommand(true)

    fetch(`/api/services/cmd?service=${service.id}&cmd=${cmd}`)
      .then(response => {
        switch (response.status) {
          case 200:
            handleLogsResponse(response)
            break
          case 401:
            alert('You do not have the required permissions.')
            break
          case 404:
            alert('Command not defined.')
            break
          case 500:
            alert('Server error.')
            break
        }
      })
      .finally(() => setSendingCommand(false))
  }

  const toggleService = (start: boolean) => {
    post(`/api/services/${start ? 'start' : 'stop'}`, {
      body: JSON.stringify({ service: service.id })
    }).then(response => {
      if (!response.ok) {
        alert('You do not have the required permissions.')
      } else {
        setServiceUpdating(true)
        setServiceUpdatingStatus(service.status)
        resetUpdating()
      }
    })
  }

  const resetUpdating = () => {
    setTimeout(() => {
      setServiceUpdating(false)
      setServiceUpdatingStatus(-1)
    }, 10000)
  }

  const hasPermission = user && user.role >= 2

  return (
    <CardContainer wide title={service.name} className={styles.servicePage}>
      <h3>Status</h3>
      <div className={styles.statusContainer}>
        <span className={styles.status}>
          {serviceUpdating && service.status === serviceUpdatingStatus
            ? <StatusLabel color={StatusLabelColors.Gray}>{service.status === 0 ? 'Starting' : 'Stopping'}</StatusLabel>
            : formatStatus(service.status)}

          <span>{statusDescription(service.status)}</span>
        </span>
        {hasPermission &&
          <div style={{ marginTop: '12px' }}>
            <button
              disabled={!service.hasStart || service.running}
              onClick={() => toggleService(true)}
            >
              Start
            </button>

            <button
              disabled={!service.hasStop || !service.running}
              onClick={() => toggleService(false)}
            >
              Stop
            </button>
          </div>}
      </div>

      {hasPermission && service.commands &&
        <>
          <h3>Commands</h3>
          <div className={styles.commands}>
            {service.commands.map((cmd, i) =>
              <button key={i} disabled={sendingCommand} onClick={() => sendCommand(i)}>{cmd.text}</button>
            )}
          </div>
        </>}

      {error && <div className={styles.error}>{error}</div>}

      <h3 style={{ position: 'relative' }}>
        Log
        <div className={styles.autoRefresh}>
          <span className={styles.autoRefreshLabel}>Auto-refresh</span>
          <Switch height={20} width={40} onColor='#008CBA' onChange={handleAutoRefreshChange} checked={autoRefresh} />
        </div>
      </h3>
      <textarea className={styles.log} readOnly value={log} ref={textLog} />
    </CardContainer>
  )
}

export default ServicePage
