// @flow
import { useEffect, useReducer, useState } from 'react'
import { useSelector } from 'react-redux'
import { ReactSVG } from 'react-svg'
import { fdelete } from 'Shared/Common'
import CardContainer from '../CardContainer/CardContainer'
import Banner from './Banner/Banner'
import BannerModal from './BannerModal'
import styles from './Home.css'
// $FlowFixMe
import { AccountType } from 'Shared/Types'

const Home = () => {
  const [loggedIn, user, initialAccounts] = useSelector((state) => [
    state.loggedIn,
    state.user,
    state.accounts
  ])
  const [editBanner, setEditBanner] = useState(-1)
  const [editing, setEditing] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [accounts, setAccounts] = useState(initialAccounts)
  const [, forceUpdate] = useReducer(x => x + 1, 0)

  useEffect(() => {
    document.title = 'Home - ' + document.title
  })

  const handleAddNewBanner = (e) => {
    if (e.currentTarget.dataset.hide === true) {
      return
    }

    setShowModal(true)
  }

  const handleEditButton = () => setEditing(!editing)

  const handleBannerEdit = (id: number) => {
    setEditBanner(id)
    setShowModal(true)
  }

  const handleBannerDelete = (id: number) => {
    if (confirm('Are you sure?') === false) {
      return
    }

    fdelete(`/api/apps/${id}`).then(() => {
      // Remove from accounts
      setAccounts(accounts.filter(x => x.id !== id))
      forceUpdate()
    }).catch(reason => {
      alert('Error.')
      console.error(reason)
    })
  }

  const handleUpdateAccounts = (createdNew, account: ?AccountType) => {
    if (createdNew && account) {
      const newAccounts = accounts
      const i = newAccounts.findIndex(x => account && x.id === account.id)
      if (i === -1) {
        newAccounts.push(account)
      } else {
        newAccounts[i] = account
      }

      setAccounts(newAccounts)
    }

    setShowModal(false)
    setEditBanner(-1)
  }

  const userHasAccess = loggedIn && user && user.role >= 2
  const adminAccess = user && user.role >= 100

  return (
    <>
      <CardContainer className={styles.home} big title='Welcome!'>
        <div>This website is used as a playground to host whatever project I wanted to create.</div>
      </CardContainer>

      <CardContainer className={styles.home} big title='Apps'>
        {adminAccess &&
          <div className={styles.adminTools}>
            <button className={styles.editButton} onClick={handleEditButton}>
              {editing ? 'Done' : 'Edit'}
            </button>
          </div>}

        <ul>
          {accounts && accounts.map((account, index) => {
            if (editing || !account.hidden) {
              return (
                <Banner
                  key={index}
                  account={account}
                  userHasAccess={userHasAccess}
                  editing={editing}
                  onEdit={() => { handleBannerEdit(account.id) }}
                  onDelete={() => { handleBannerDelete(account.id) }}
                />
              )
            }
          })}
        </ul>

        {adminAccess &&
          <div className={styles.addNewBanner} data-hide={!editing}>
            <button className={styles.addNewBannerButton} data-nostyling data-hide={!editing} onClick={handleAddNewBanner}>
              <ReactSVG src='/public/plus.svg' className={styles.addNewBannerIcon} />
            </button>
          </div>}

        {(adminAccess && showModal) &&
          <BannerModal
            account={editBanner > -1 ? accounts.find(x => x.id === editBanner) : null}
            showModal={showModal}
            onClose={handleUpdateAccounts}
          />}
      </CardContainer>
    </>
  )
}

export default Home
