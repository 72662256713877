// @flow
import React, { PureComponent } from 'react'
import styles from './Register.css'
import autobind from 'class-autobind'
import CardContainer from '../../CardContainer/CardContainer'

type State = {
  displayName: string,
  email: string,
  password: string,
  passwordRepeat: string,
  errors: string[]
}

function isNull (value: string,
                 index: number,
                 array: string[]): boolean {
  return !value
}

export default class Register extends PureComponent<void, State> {
  constructor (props: any) {
    super(props)

    this.state = {
      displayName: '',
      email: '',
      password: '',
      passwordRepeat: '',
      errors: []
    }
    autobind(this)
  }

  handleInputChange (event: any) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    this.setState({
      [name]: value
    })
  }

  handleSubmit (event: any) {
    event.preventDefault()
    const { displayName, email, password, passwordRepeat } = this.state
    const checknull = [displayName, email, password, passwordRepeat]

    // Check if any input is null
    if (checknull.some(isNull)) {
      this.setState({
        errors: ['All fields needs to be filled.']
      })
    } else if (password !== passwordRepeat) {
      this.setState({
        errors: ['Passwords doesn\'t match!']
      })
    } else {
      fetch('/api/users/register', {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify({
          displayName,
          email,
          password,
          passwordRepeat
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        if (response.ok || response.status === 500) {
          return response
        } else {
          return response.json().then(data => ({
            ok: response.ok,
            status: response.status,
            body: data
          }))
        }
      }).then((response: any) => {
        if (response.ok) {
          // Success, navigate to home
          window.location = '/'
        } else if (response.status === 403) {
          alert(response.body.message)

          this.setState({
            errors: []
          })
        } else if (response.status === 422) {
          // Show errors to user
          this.setState({
            errors: Object.values(response.body.errors).map((err: any) => err.msg)
          })
        } else {
          throw new Error('Unknown error. Check console.')
        }
      }).catch(error => {
        alert(error)
        console.error(error)
      })
    }
  }

  render () {
    const { displayName, email, password, passwordRepeat, errors } = this.state

    return (
      <CardContainer className={styles.registerPage}>
        <h2>Register</h2>
        {errors && errors.length > 0 && <section className={styles.errors}>
          <ul>
            {errors.map((err, i) => (
              <li key={i}>{err}</li>
            ))}
          </ul>
        </section>}
        <form onSubmit={this.handleSubmit}>
          <div>
            <label>Display Name:</label>
            <input
              type='text'
              label='Display Name'
              name='displayName'
              value={displayName}
              onChange={this.handleInputChange} />
          </div>
          <div>
            <label>Email:</label>
            <input
              autoComplete='username'
              type='email'
              label='Email'
              name='email'
              value={email}
              onChange={this.handleInputChange} />
          </div>
          <div>
            <label>Password:</label>
            <input
              autoComplete='new-password'
              type='password'
              label='Password'
              name='password'
              value={password}
              onChange={this.handleInputChange} />
          </div>
          <div>
            <label>Repeat:</label>
            <input
              autoComplete='new-password'
              type='password'
              label='Repeat'
              name='passwordRepeat'
              value={passwordRepeat}
              onChange={this.handleInputChange} />
          </div>
          <div>
            <input type='submit' value='Register' />
            <button className={styles.backButton} type='button'>
              <a href='/login'><span>Back</span></a>
            </button>
          </div>
        </form>
      </CardContainer>
    )
  }
}
