// @flow
import CardContainer from 'Components/CardContainer/CardContainer'
import { useSelector } from 'react-redux'
import { put } from 'Shared/Common'
import { FriendType } from 'Shared/Types'
import styles from './FriendsPage.css'

type ReduxState = {
  friends: FriendType[],
  pending: FriendType[]
}

const FriendsPage = () => {
  const { friends, pending } = useSelector((state: ReduxState) => state)

  const handleAccept = (index: number) => {
    put('/api/users/friends_pending/accept/' + pending[index].id)
      .then(() => {
        window.location.reload()
      })
      .catch(error => {
        alert('Error')
        console.error(error)
      })
  }

  const handleRemove = (index: number) => {
    put('/api/users/friends_pending/remove/' + pending[index].id)
      .then(() => {
        window.location.reload()
      })
      .catch(error => {
        alert('Error')
        console.error(error)
      })
  }

  return (
    <CardContainer className={styles.friendsPage}>
      {pending.length > 0 &&
        <div>
          <h3>Pending</h3>
          <ul>
            {pending.map((x, index) =>
              <li key={index}>
                <a href={'/users/' + x.id}>
                  <img src={x.avatarMedium || '/public/avatar_default.jpg'} />{x.displayName}
                  <div>
                    <button onClick={() => handleAccept(index)}>Accept</button>
                    <button onClick={() => handleRemove(index)}>Remove</button>
                  </div>
                </a>
              </li>
            )}
          </ul>
        </div>
      }

      <h3>Your Friends</h3>
      <ul>
        {friends.map((x, index) =>
          <li key={index}>
            <a href={'/users/' + x.id}>
              <img src={x.avatarMedium || '/public/avatar_default.jpg'} />{x.displayName}
            </a>
          </li>
        )}
      </ul>
    </CardContainer>
  )
}

export default FriendsPage
