// @flow
import CardContainer from 'Components/CardContainer/CardContainer'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import styles from './Search.css'

const DEFAULT_AVATAR = '/public/avatar_default.jpg'
const ERROR_EMPTY_SEARCH = 'Search term can\'t be empty.'

type SearchResult = {
  displayName: String,
  id: Number,
  avatarMedium: String
}

type ReduxState = {
  results: SearchResult[]
}

const Search = () => {
  const { results: initialResults } = useSelector((state: ReduxState) => state)
  const [results,] = useState(initialResults)
  const [search, setSearch] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const location = useLocation()

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const name = params.get('name')

    setErrorMsg(!name && params.has('name') ? ERROR_EMPTY_SEARCH : '')
    setSearch(name || '')
  }, [])

  const handleSearch = (event: Event) => {
    if (!search) {
      event.preventDefault()
      setErrorMsg(ERROR_EMPTY_SEARCH)
    }
  }

  return (
    <CardContainer title='Search' className={styles.search}>
      <div className={styles.description}>Search for users.</div>

      <form className={styles.searchForm} onSubmit={handleSearch}>
        <input
          type='text'
          label='Search'
          name='name'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />

        <input type='submit' value='Search' />
      </form>

      {errorMsg && <div className={styles.errorMessage}>{errorMsg}</div>}

      <ul className={styles.searchResult}>
        {results && results.map((x, i) =>
          <li key={i}>
            <a href={'/users/' + x.id}>
              <img className={styles.avatar} src={x.avatarMedium || DEFAULT_AVATAR} />
              <span>{x.displayName}</span>
            </a>
          </li>)}
      </ul>
    </CardContainer>
  )
}

export default Search
