// @flow
import { ReactSVG } from 'react-svg'
import { GameRequestType } from 'Shared/Types'
import styles from './GameRequestItem.css'

type Props = {
  gameRequest: GameRequestType,
  renderButtons: boolean,
  onApprove: (id: number) => void,
  onReject: (id: number) => void,
  onDelete: (id: number) => void
}

const statusBackgroundColor = (status: number) => {
  switch (status) {
    case 0: return ''
    case 1: return '#47D0AA'
    case 2: return '#F77A6A'
  }
}

const statusIcon = (status: number) => {
  switch (status) {
    case 0: return '/public/minus.svg'
    case 1: return '/public/success.svg'
    case 2: return '/public/error.svg'
  }
}

const GameRequestItem = (props: Props) => {
  const { gameRequest, renderButtons, onApprove, onReject, onDelete } = props

  return (
    <li className={styles.gameRequestItem}>
      <div style={{ backgroundColor: statusBackgroundColor(gameRequest.status) }}>
        <ReactSVG
          src={statusIcon(gameRequest.status)}
          style={{ width: '32px', height: '32px' }} />
      </div>
      <div>
        <a className={styles.avatarLink} href={`/users/${gameRequest.user}`}>
          <img
            className={styles.avatar}
            src={gameRequest.avatar} />
          <span><b>{gameRequest.displayName}</b></span>
        </a>
        <span className={styles.topLine}> wants to play <b>{gameRequest.game}</b></span>

        <blockquote>{gameRequest.message}</blockquote>

        <div>
          {renderButtons && (
            <div className={styles.gameRequestItemButtons}>
              <button data-nostyling onClick={() => onApprove(gameRequest.id)}>
                <ReactSVG src='/public/approve.svg' />
              </button>
              <button data-nostyling onClick={() => onReject(gameRequest.id)}>
                <ReactSVG src='/public/reject.svg' />
              </button>
              <button data-nostyling onClick={() => onDelete(gameRequest.id)}>
                <ReactSVG src='/public/rubbish-bin.svg' />
              </button>
            </div>
          )}
        </div>
      </div>
    </li>
  )
}

export default GameRequestItem
