// @flow
import path from 'path'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { UserType } from 'Shared/Types'
import styles from './AdminProgress.css'
import ProgressItem from './ProgressItem'

type ReduxProps = {
  user: UserType
}

type Item = {
  title: string,
  url: string,
  progress: number,
  status: string,
  filename: string,
  format: string
}

const AdminProgress = () => {
  const timeoutHandle = useRef()
  const { user } = useSelector((state: ReduxProps) => state)
  const [items, setItems] = useState([])

  const checkForUpdates = (stopTimeout = false) => {
    fetch('/api/youtube-dl/get/all', { credentials: 'include' })
      .then(response => response.json())
      .then(response => {
        setItems(response.map((item: Item) => ({
          title: path.basename(item.title, 'mp4'),
          url: item.url,
          progress: item.progress,
          status: item.status,
          filename: item.filename || '',
          format: item.format
        })))
      })
      .catch(reason => console.error(reason))

    if (stopTimeout) {
      clearTimeout(timeoutHandle.current)
    }

    timeoutHandle.current = setTimeout(() => checkForUpdates(), 2000)
  }

  const hasAccess = () => user && user.role >= 100

  const handleClear = () => {
    fetch('/api/youtube-dl/clear', {
      credentials: 'include',
      method: 'DELETE'
    })
  }

  const handleClearCompleted = () => {
    fetch('/api/youtube-dl/clear/completed', {
      credentials: 'include',
      method: 'DELETE'
    })
  }

  useEffect(() => {
    if (!hasAccess()) {
      return
    }

    checkForUpdates()
  }, [])

  if (!hasAccess()) {
    return null
  }

  return (
    <div className={styles.progress}>
      <h2>Progress</h2>
      <div>
        <button
          disabled={items.length === 0}
          onClick={handleClear}>Clear</button>
        <button
          disabled={items.length === 0}
          onClick={handleClearCompleted}>Clear Completed</button>
      </div>
      <ul>
        {items.map((item, index) => (
          <ProgressItem key={index} item={item} />
        ))}
      </ul>
    </div>
  )
}

export default AdminProgress
