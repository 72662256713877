import React from 'react'
import { createRoot, hydrateRoot } from 'react-dom/client'
import domready from 'domready'

import { Provider } from 'react-redux'
import configureStore from 'Shared/Store'

import App from 'Shared/App'
import { BrowserRouter } from 'react-router-dom'

const mainMountId = 'home-server-mount'

const data = window.__INITIAL_STATE__
const version = window.__VERSION__
const store = configureStore(data)
window.dev = store

function init () {
  const mountNode = document.getElementById(mainMountId)
  const component = (
    <Provider store={store}>
      <BrowserRouter>
        <App version={version} />
      </BrowserRouter>
    </Provider>)

  const root = module.hot ? createRoot(mountNode) : hydrateRoot(mountNode, component)
  if (module.hot)
    root.render(component)
}

domready(init)
