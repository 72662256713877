// @flow
import { useState } from 'react'
import styles from './CheckBox.css'

type Props = {
  name: string,
  label: string,
  initiallyChecked: boolean,
  onValueChanged: (name: string, checked: boolean) => void
}

const CheckBox = (props: Props) => {
  const [checked, setChecked] = useState(props.initiallyChecked)

  const handleInputChange = (event: any) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value

    setChecked(value)
    props.onValueChanged(props.name, value)
  }

  const { name, label } = props

  return (
    <label className={styles.checkboxContainer}>{label}
      <input
        className={styles.checkboxBox}
        type='checkbox'
        label={label}
        name={name}
        checked={checked}
        onChange={handleInputChange}
      />
      <span className={styles.checkboxMark} />
    </label>
  )
}

export default CheckBox
