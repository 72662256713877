// @flow
import classnames from 'classnames'
import CardContainer from 'Components/CardContainer/CardContainer'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import styles from './Reports.css'

const Filters = {
  '': 0,
  '#all': 0,
  '#active': 1,
  '#approved': 2,
  '#rejected': 3
}

const Reports = () => {
  const { reports } = useSelector(state => state)
  const [filter, setFilter] = useState(0)
  const location = useLocation()

  useEffect(() => {
    setFilter(Filters[location.hash.toLowerCase()])
  }, [])

  const handleSetFilter = (filter: number) => {
    return () => setFilter(filter)
  }

  return (
    <CardContainer className={styles.reports} title='Reports' wide>
      <div className={styles.filters}>
        <a
          className={classnames({ [styles.active]: filter === 0 })}
          href='#all'
          onClick={handleSetFilter(0)}
        >All ({reports.length})</a>

        <a
          className={classnames({ [styles.active]: filter === 1 })}
          href='#active'
          onClick={handleSetFilter(1)}
        >Active</a>

        <a
          className={classnames({ [styles.active]: filter === 2 })}
          href='#approved'
          onClick={handleSetFilter(2)}
        >Approved</a>

        <a
          className={classnames({ [styles.active]: filter === 3 })}
          href='#rejected'
          onClick={handleSetFilter(3)}
        >Rejected</a>
      </div>

      <ul className={styles.reportsList}>
        {reports.map((report, index) => {
          return (
            <li className={styles.reportItem} key={index}>
              <div className={styles.reportHeader}>
                <a href={`/users/${report.reporter.id}`}>
                  <img src={report.reporter.avatarMedium} />{report.reporter.displayName}
                </a>
                <span> reported </span>
                <a href={`/users/${report.commenter.id}`}>
                  <img src={report.commenter.avatarMedium} />{report.commenter.displayName}
                </a>
                <span>&#39;s comment:</span>
              </div>
              <div className={styles.comment}>
                <div>{moment(report.comment_date).format('llll')}</div>
                <div>{report.comment_text}</div>
                <a href={`/users/${report.comment_user_id}?comment=${report.comment_id}`}>View conversation</a>
              </div>
              <div className={styles.report}>
                <h4>Report:</h4>
                <div>{moment(report.report_date).format('llll')}</div>
                <div>{report.report_text}</div>
                <a href='#'>Approve</a>
                <a href='#'>Reject</a>
              </div>
            </li>
          )
        })}
      </ul>
    </CardContainer>
  )
}

export default Reports
