// @flow
import classnames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { fdelete, post, put } from 'Shared/Common'
import { GameRequestType, UserType } from 'Shared/Types'
import CardContainer from '../CardContainer/CardContainer'
import GameRequestItem from './GameRequestItem'
import styles from './GameRequests.css'

type ReduxState = {
  user: UserType,
  gameRequests: GameRequestType[]
}

const GameRequests = () => {
  const statusTimeoutHandle = useRef()
  const { user, gameRequests } = useSelector((state: ReduxState) => state)
  const [status, setStatus] = useState('Status :D')
  const [statusColor, setStatusColor] = useState('green')
  const [submitStatusClass, setSubmitStatusClass] = useState(styles.submitStatus)
  const [game, setGame] = useState('')
  const [message, setMessage] = useState('')
  const [requests, setRequests] = useState(gameRequests)
  const [errors, setErrors] = useState([])
  const [open, setOpen] = useState(false)

  const handleSubmit = (event) => {
    event.preventDefault()

    post('/api/game-requests/', {
      body: JSON.stringify({ game, message })
    }).then(response => {
      if (response.ok) {
        setGame('')
        setMessage('')
        setErrors([])
        setStatus('Submitted!')
        setStatusColor('green')
        setSubmitStatusClass(classnames(styles.submitStatus, [styles.animation]))
        checkForUpdates()
      } else if (response.status !== 422) {
        setStatus('Failed!')
        setStatusColor('red')
        setSubmitStatusClass(classnames(styles.submitStatus, [styles.animation]))
      }

      return response.json().then(data => ({
        ok: response.ok,
        status: response.status,
        body: data
      }))
    }).then(response => {
      if (response.status === 422) {
        // Show errors to user
        setErrors(Object.values(response.body.errors).map((err: any) => err.msg))
      }
    })

    if (statusTimeoutHandle.current) {
      clearTimeout(statusTimeoutHandle.current)
    }

    statusTimeoutHandle.current = setTimeout(() => {
      setSubmitStatusClass(styles.submitStatus)
      statusTimeoutHandle.current = null
    }, 2000)
  }

  const checkForUpdates = () => {
    fetch('/api/game-requests')
      .then(response => response.json())
      .then(json => setRequests(json))
  }

  const deleteRequest = (id: number) => {
    fdelete(`/api/game-requests/${id}`).then(response => {
      if (response.ok) {
        checkForUpdates()
      } else if (response.status === 401) {
        alert('Not authorized')
      } else {
        alert('Error')
      }
    })
  }

  const updateStatus = (id: number, status: number) => {
    put(`/api/game-requests/${id}`, {
      body: JSON.stringify({ status })
    }).then(response => {
      if (response.ok) {
        checkForUpdates()
      } else if (response.status === 401) {
        alert('Not authorized')
      } else {
        alert('Error')
      }
    })
  }

  useEffect(() => {
    document.title = 'Game Requests - ' + document.title
    setInterval(() => checkForUpdates(), 5000)
  }, [])

  return (
    <CardContainer className={styles.gameRequests} title='Game Requests'>
      <div>
        Here you can make a request to play a game with me, and I will be notified on my phone.
        Was just used as a project to work with SQL and <a href='https://www.pushbullet.com/'>Pushbullet</a>.
      </div>
      <div className={styles.clickableHeader} disabled={!user} onClick={() => {
        if (user) {
          setOpen(!open)
        }
      }}>
        <span>Make a request</span>
        {!user && <i> (Login required)</i>}
        <span data-open={open}>▼</span>
      </div>
      <form className={styles.gameRequestsForm} onSubmit={handleSubmit} data-open={open}>
        <p>Game:</p>
        <input
          type='text'
          label='Game'
          name='game'
          value={game}
          onChange={(e) => setGame(e.target.value)} />
        <p>Message</p>
        <textarea
          text='text' /* eslint-disable-line react/no-unknown-property */
          label='Message'
          name='message'
          value={message}
          onChange={(e) => setMessage(e.target.value)} />
        <div className={styles.buttonContainer}>
          <span className={submitStatusClass}>
            <font color={statusColor}>{status}</font>
          </span>

          {errors && errors.length > 0 && <section className={styles.errors}>
            <ul>
              {errors.map((err, i) => (
                <li key={i}>{err}</li>
              ))}
            </ul>
          </section>}

          <input type='submit' value='Submit' />
        </div>
      </form>
      <div className={styles.requestsWrapper}>
        <h3>Requests</h3>
        {requests && (
          <div>
            {requests.length === 0 ? (
              <i>No requests</i>
            ) : (
              <ul className={styles.gameRequestsList}>
                {requests.map((req, index) => {
                  return (
                    <GameRequestItem
                      key={index}
                      gameRequest={req}
                      renderButtons={user && user.role >= 3}
                      onApprove={(id) => { updateStatus(id, 1) }}
                      onReject={(id) => { updateStatus(id, 2) }}
                      onDelete={(id) => { deleteRequest(id) }} />
                  )
                })}
              </ul>
            )}
          </div>
        )}
      </div>
    </CardContainer>
  )
}

export default GameRequests
