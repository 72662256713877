// @flow
import CardContainer from 'Components/CardContainer/CardContainer'
import { useSelector } from 'react-redux'
import { ArticleType } from 'Shared/Types'
import styles from './ArticlePage.css'

type ReduxProps = {
  article: ArticleType
}

const format = (text: string): string => {
  return text ? text.replace(/\[img=(.*?)\]/gi, '<img src="$1" />')
    .replace(/\[url=(.*?)\](.*?)\[\/url\]/gi, '<a href="$1">$2</a>')
    .replace(/\[b\](.*?)\[\/b\]/gi, '<b>$1</b>')
    .replace(/\[i\](.*?)\[\/i\]/gi, '<i>$1</i>') : ''
}

const ArticlePage = () => {
  const { article } = useSelector((state: ReduxProps) => state)

  return (
    <CardContainer className={styles.article} title={article.title}>
      <div
        className={styles.content}
        dangerouslySetInnerHTML={{ __html: format(article.content) }}
      />
      <div className={styles.articleFooter}>
        Written by <a href={'/users/' + article.creatorId}>{article.creator}</a>
      </div>
    </CardContainer>
  )
}

export default ArticlePage
