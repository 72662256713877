// @flow
import classnames from 'classnames'
import hhmmss from 'hhmmss'
import Mousetrap from 'mousetrap'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { ReactSVG } from 'react-svg'
import { SongType, UserType } from 'Shared/Types'
import CardContainer from '../CardContainer/CardContainer'
import styles from './Music.css'

/* ToDo:
 * - Listening time tracker
 * - PLayed songs tracker
 */

type ReduxState = {
  user: UserType,
  songs: SongType[]
}

/**
  * Returns random number between min (inclusive) to max (exclusive).
  */
function getRandomInt(min: number, max: number): number {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min)) + min // The maximum is exclusive and the minimum is inclusive
}

const sortSongs = (sortColumn, sortDesc) => {
  return (a, b) => {
    if (!a.name) {
      return 1
    }
    if (!b.name) {
      return -1
    }

    let sortA
    let sortB

    switch (sortColumn) {
      case 0:
        sortA = a.name.toLowerCase()
        sortB = b.name.toLowerCase()
        break
      case 1:
        sortA = a.artist.toLowerCase()
        sortB = b.artist.toLowerCase()
        break
      case 2:
        // ToDo: Actually sort by album. Album isn't stored atm
        sortA = (a.album || '').toLowerCase()
        sortB = (b.album || '').toLowerCase()
        break
      case 3:
        sortA = a.duration
        sortB = b.duration
        break
      default:
        throw new Error('Unknown sorting column')
    }

    if (sortA < sortB) {
      return sortDesc ? 1 : -1
    } else if (sortA > sortB) {
      return sortDesc ? -1 : 1
    } else {
      // Sort by name now
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return sortDesc ? 1 : -1
      } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return sortDesc ? -1 : 1
      }
    }

    return 0
  }
}

const Music = () => {
  const { user, songs: initialSongs } = useSelector((state: ReduxState) => state)
  const _audio = useRef()
  const _stickyTab = useRef()
  const _hotkeys = useRef()
  const [history, setHistory] = useState([0])
  const [songs, setSongs] = useState(initialSongs)
  const [nowPlaying, setNowPlaying] = useState(songs[0] ? `${songs[0].name} - ${songs[0].artist}` : '')
  const [playingId, setPlayingId] = useState(songs.length > 0 ? 0 : -1)
  const [repeat, setRepeat] = useState(false)
  const [shuffle, setShuffle] = useState(false)
  const [stickyTab, setStickyTab] = useState(false)
  const [sortColumn, setSortColumn] = useState(0)
  const [sortDesc, setSortDesc] = useState(false)

  const bindHotkeys = () => {
    _hotkeys.current = {
      k: () => {
        if (_audio.current.paused) {
          _audio.current.play()
        } else {
          _audio.current.pause()
        }
      },
      n: () => playNext(true),
      p: () => playPrevious()
    }
  }

  useEffect(() => {
    document.title = 'Music - ' + document.title
    bindHotkeys()

    Object.keys(_hotkeys.current).forEach((key) => {
      Mousetrap.bind(key, _hotkeys.current[key])
    })

    window.onbeforeunload = (e) => {
      if (!_audio.current.paused && stickyTab) {
        let msg = 'Are you sure you wanna leave?'
        e.returnValue = msg
        return msg
      }
    }

    const stickyTab = (window.localStorage.getItem('stickyTab') == 'true')

    _stickyTab.current.checked = stickyTab

    _audio.current.volume = parseFloat(window.localStorage.volume || 0.5)
    _audio.current.src = `/public/music/${songs[0].file}`
    _audio.current.load()

    setStickyTab(stickyTab)

    return () => {
      Object.keys(_hotkeys.current).forEach((key) => {
        Mousetrap.unbind(key, _hotkeys.current[key])
      })
    }
  }, [])

  const getSongs = (forceRefresh: boolean = false) => {
    return new Promise((resolve: (result: any) => void, reject) => {
      fetch('/api/music' + (forceRefresh ? '?force_refresh' : ''))
        .then(response => response.json())
        .then(songs => {
          _audio.current.src = `/public/music/${songs[0].file}`
          _audio.current.load()

          resolve({
            history: [0],
            songs,
            nowPlaying: `${songs[0].name} - ${songs[0].artist}`,
            playingId: 0
          })
        })
        .catch((reason) => reject(reason))
    })
  }

  const loopSong = () => {
    _audio.current.currentTime = 0
    _audio.current.play()
  }

  /**
   * Plays next song based on playing options (shuffle, repeat).
   * @param {Boolean} force Ignore repeat/looping.
   */
  const playNext = (force: boolean = false) => {
    if (!force && repeat) {
      loopSong()
    } else if (shuffle) {
      playSong(getRandomInt(0, songs.length), true, true)
    } else {
      // Index of the next song to play in 'songs' list
      let index = songs.find(x => x.id === playingId).index + 1

      if (index === songs.length) {
        // Currently playing last song, hop back to start
        index = 0
      }
      playSong(songs[index].id, true, false, [])
    }
  }

  const playPrevious = () => {
    if (!shuffle) {
      let index = songs.find(x => x.id === playingId).index - 1
      // Check if currently playing first song. If true, hop to end
      if (index < 0) index = songs.length - 1

      playSong(songs[index].id, true)
    } else {
      let index = songs.find(x => x.id === playingId).index
      const history = history.slice()
      history.pop() // Removes currently playing song
      const prevId = history[history.length - 1]

      // Check if there is a previous played song, and if true play that next
      if (prevId >= 0) {
        index = songs.find(x => x.id === prevId).index
      } else {
        // Otherwise enumerate songs backwards
        if (index === 0) {
          // Currently playing first song, hop to end
          index = songs.length - 1
        } else {
          index -= 1
        }
      }
      playSong(songs[index].id, true, false, history)
    }
  }

  /**
   * Plays song.
   * @param {Number} id Id of the song to play.
   * @param {Boolean} play True to auto play song, false to just load.
   * @param {Boolean} addToHistory True to add to playing history.
   * @param {Array} useHistory Used to pass a modified history but keep setState calls to minimum.
   */
  const playSong = (id: number,
    play?: boolean = true,
    addToHistory: boolean = false,
    useHistory: number[] = history.slice()) => {
    if (!_audio.current)
      return

    const song = songs.find(x => x.id === id)
    _audio.current.src = '/public/music/' + song.file
    _audio.current.load()

    if (play) {
      _audio.current.play()
    }

    /* Check if last item is same as new.
     * Can happen when history is empty, playing id is 0 and playPrevious was called */
    if (addToHistory && useHistory[useHistory.length - 1] !== id) {
      useHistory.push(id)
    }

    setHistory(useHistory)
    setNowPlaying(song.name + ' - ' + song.artist)
    setPlayingId(id)
  }

  const clickColumn = (event) => {
    const getIndex = (el) => {
      var index = 0
      while ((el = el.previousElementSibling)) {
        index++
      }
      return index
    }
    let sortColumn = sortColumn
    let sortDesc = sortDesc
    const index = getIndex(event.target)

    if (index === sortColumn) {
      sortDesc = !sortDesc
    } else {
      sortColumn = index
      sortDesc = false
    }

    setSongs(songs
      .sort(sortSongs(sortColumn, sortDesc))
      .map((x, index) => ({ ...x, index })))
    setSortColumn(sortColumn)
    setSortDesc(sortDesc)
  }

  const DebuggingTools = () => {
    const handleRefresh = () => {
      getSongs(true).then(({ history, songs, nowPlaying, playingId }, err) => {
        if (err) throw err
        setHistory(history)
        setSongs(songs)
        setNowPlaying(nowPlaying)
        setPlayingId(playingId)
      })
    }

    return (
      <div style={{ float: 'right', position: 'absolute', top: '8px', right: '8px' }}>
        <span style={{ marginRight: '8px' }}>Admin tools</span>
        <button onClick={handleRefresh}>Refresh</button>
      </div>
    )
  }

  const sortArrow = (column) => sortColumn === column ? (sortDesc ? '▼' : '▲') : null

  return (
    <CardContainer className={styles.musicCard} wide>
      {(!user || user.role < 100) &&
        <h3 className={styles.musicWarning}>Music is only available to the owner.</h3>
      }

      <h1>Music</h1>

      {user && user.role >= 100 && <DebuggingTools />}

      <div className={styles.nowPlaying}>
        <span>Now playing:</span>
        <span> {nowPlaying}</span>
        <span>({songs.find(x => x.id === playingId).index + 1}/{songs.length})</span>
      </div>
      <audio
        className={styles.musicPlayer}
        /* eslint-disable react/no-unknown-property */
        controls
        onEnded={e => { e.preventDefault(); playNext(); }}
        onVolumeChange={(event) => { window.localStorage.setItem('volume', event.target.volume) }}
        /* eslint-enable react/no-unknown-property */
        ref={_audio} />
      <div className={styles.playingOptions}>
        <span>
          <button onClick={() => playPrevious()}>Prev</button>
          <button onClick={() => playNext(true)}>Next</button>
        </span>
        <label>
          <input
            type='checkbox'
            name='shuffle'
            label='Shuffle'
            value={shuffle}
            onChange={e => setShuffle(e.target.checked)} />
          Shuffle
        </label>
        <label>
          <input
            type='checkbox'
            name='repeat'
            label='Repeat'
            value={repeat}
            onChange={e => setRepeat(e.target.checked)} />
          Repeat
        </label>
        <div className={styles.rightOption}>
          <label>
            <input
              type='checkbox'
              name='stickyTab'
              label='Sticky Tab'
              value={stickyTab}
              onChange={e => {
                setStickyTab(e.target.checked)
                window.localStorage.setItem('stickyTab', e.target.checked)
              }}
              ref={_stickyTab} />
            Sticky Tab
          </label>
          <div className={styles.tooltip}>
            <ReactSVG
              src='/public/exclamation_mark.svg'
              className={styles.informationIcon} />
            <span className={styles.tooltipText}>Turn on to confirm tab closing to avoid accidentally stopping music.</span>
          </div>
        </div>
      </div>
      <table className={styles.musicList} cellSpacing='0'>
        <thead>
          <tr>
            {/* eslint-disable react/no-unknown-property */}
            <th align='left' onClick={clickColumn}>{sortArrow(0)} Song</th>
            <th align='left' onClick={clickColumn}>{sortArrow(1)} Artist</th>
            <th align='left' onClick={clickColumn}>{sortArrow(2)} Album</th>
            <th align='left' onClick={clickColumn}>{sortArrow(3)} Duration</th>
            {/* eslint-enable react/no-unknown-property */}
          </tr>
        </thead>
        <tbody>
          {songs.map((song, index) => {
            const duration = hhmmss(song.duration)
            const SongLink = (props) => (
              <td>
                <a
                  className={classnames({ [styles.playing]: playingId === song.id })}
                  href={'/public/music/' + song.file}
                  onClick={(event) => {
                    event.preventDefault();
                    playSong(song.id, true, shuffle);
                  }}>{props.children}</a> {/* eslint-disable-line react/prop-types */}
              </td>
            )

            return (
              <tr key={index}>
                <SongLink>{song.name}</SongLink>
                <SongLink>{song.artist}</SongLink>
                {song.album ?
                  <SongLink>{song.album}</SongLink>
                  :
                  <SongLink><font color='gray'>No album specified</font></SongLink>
                }
                <SongLink>{duration}</SongLink>
              </tr>
            )
          })}
        </tbody>
      </table>
    </CardContainer >
  )
}

export default Music
