// @flow
import classnames from 'classnames'
import VolumeBar from 'Components/VolumeBar/VolumeBar'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import useStateRef from 'react-usestateref'
import styles from './Countdown.css'
import CountdownWorker from './CountdownWorker'
import { Dialer, type DialerEvent } from './Dialer'
import TimeList from './TimeList/TimeList'
import Timer from './Timer'

const HHMMSSToSeconds = (hhmmss: string): number => moment.duration().add(hhmmss).asSeconds()

const secondsToHHMMSS = (totalSeconds: number): string => {
  const duration = moment.duration(parseInt(totalSeconds), 'seconds')
  const hours = duration.hours() + ''
  const minutes = duration.minutes() + ''
  const seconds = duration.seconds() + ''
  return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}:${seconds.padStart(2, '0')}`
}

const Countdown = () => {
  let bell = useRef()
  let defaultTitle = useRef()
  let countdownWorker = useRef()
  let _mounted = useRef(false)

  const [running, setRunning] = useState(false)
  const [timer, setTimer, timerRef] = useStateRef('00:00:00')
  const [recentTimes, setRecentTimes] = useState([])
  const [windowTitle, setWindowTitle] = useState(null)
  const location = useLocation()

  useEffect(() => {
    window.onbeforeunload = (e) => {
      if (running) {
        return e.returnValue = 'A countdown is active. Are you sure you wanna leave?'
      }
    }
    
    bell.current = new Audio('/public/bell.mp3')
    bell.current.volume = parseFloat(window.localStorage.bellVolume || 0.5)
    bell.current.load()

    _mounted.current = true

    const parsed = new URLSearchParams(location.search)
    let time: string = timer

    if (parsed.has('time')) {
      time = parseInt(parsed.get('time'))
      time = secondsToHHMMSS(time)
    }

    setTimer(time)
    setRecentTimes(window.localStorage.recentTimes?.split(',') || [])
  }, [])

  useEffect(() => {
    if (windowTitle === null) {
      // Set initial title
      setWindowTitle(defaultTitle.current = 'Countdown - ' + document.title)
      return
    }

    document.title = windowTitle
  }, [windowTitle])

  const addToRecent = (time: string): string[] => {
    let recent = window.localStorage.recentTimes?.split(',') || []

    // Check if time already exists, in which remove it so it can be added to top
    const index = recent.indexOf(time)
    if (index > -1) {
      recent.splice(index, 1)
    }

    recent.unshift(time)
    recent = recent.slice(0, 10)
    window.localStorage.setItem('recentTimes', recent)
    return recent
  }

  const handleDigitClick = (event: DialerEvent) => {
    if (running) {
      // Timer is running, do nothing
      return
    }

    if (event.clear) {
      setTimer('00:00:00')
    } else if (event.backspace) {
      setTimer(timer.replace(/^(\d)(\d):(\d)(\d):(\d)\d/, '0$1:$2$3:$4$5'))
    } else {
      setTimer(timer.replace(/^\d(\d):(\d)(\d):(\d)(\d)/, '$1$2:$3$4:$5') + event.digit)
    }
  }

  const handleListClick = (event: any) => setTimer(event.target.dataset.time)

  const handleStartClick = (event: any) => {
    event?.preventDefault()

    const params = new URLSearchParams(location.search)
    // Also accept an empty value as true
    const old = params.has('old') && params.get('old') === '' || params.get('old')

    setWindowTitle(`${timer} - ${defaultTitle.current}`)

    countdownWorker.current = new CountdownWorker(old)
    countdownWorker.current.on('interval', () => {
      const newTimer = secondsToHHMMSS(HHMMSSToSeconds(timerRef.current) - 1)
      setTimer(newTimer)
      setWindowTitle(`${newTimer} - ${defaultTitle.current}`)
    })
    countdownWorker.current.on('done', (wasStopped: boolean) => {
      setRunning(false)
      setWindowTitle(defaultTitle.current)
      if (!wasStopped) {
        playBell()
      }

      countdownWorker.current = null
    })
    countdownWorker.current.start(HHMMSSToSeconds(timer))

    setRunning(true)
    setRecentTimes(addToRecent(timer))
  }

  const handleStopClick = (event: any) => {
    event?.preventDefault()
    countdownWorker.current.stop()
    setRunning(false)
  }

  const handleSetClick = (event: any) => {
    event?.preventDefault()
    // Pass the time in and out of the moment api
    setTimer(secondsToHHMMSS(HHMMSSToSeconds(timer)))
  }

  const isValidTimer = () => {
    const [, minutes, seconds] = timer.split(':').map(x => parseInt(x))
    return minutes < 60 && seconds < 60
  }

  const playBell = (loop: number = 3) => {
    let count = 0
    // This might not be an efficient way of doing events
    bell.current.onended = () => {
      if (++count < loop) {
        bell.current.play()
      }
    }
    bell.current.play()
  }

  /* const commonTimes = [
    '01:00:00',
    '00:12:00',
    '00:11:00',
    '00:10:00',
    '00:09:00'
  ] */

  let button = null
  if (running) {
    button = <a className={styles.red} href='#' onClick={handleStopClick}>Stop</a>
  } else {
    button = isValidTimer()
      ? <a className={styles.green} href='#' onClick={handleStartClick}>Start</a>
      : <a className={styles.green} href='#' onClick={handleSetClick}>Set</a>
  }

  return (
    <div className={styles.countdown}>
      <div className={styles.outerTimer}>
        <Timer hhmmss={timer} />

        <div className={styles.countdownGrid}>
          <Dialer className={styles.dialer} onDigitClick={handleDigitClick} />

          <div className={styles.times}>
            {/* <TimeList title='Common' times={commonTimes} onListClick={handleListClick} /> */}
            <TimeList title='Recent' times={recentTimes} onListClick={handleListClick} />
          </div>

          <div className={classnames(styles.buttons, styles.noselect)}>
            {_mounted.current &&
              <VolumeBar
                className={styles.volumeBar}
                initialVolume={parseFloat(window.localStorage.bellVolume || 0.5)}
                onVolumeChange={(volume) => {
                  bell.current.volume = volume
                  window.localStorage.setItem('bellVolume', bell.current.volume)
                }}
              />}
            {button}
            <a disabled={running} href='#' onClick={(e) => { e.preventDefault(); playBell(1) }}>Test alarm</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Countdown
