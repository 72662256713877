// @flow
import { useEffect, useRef } from 'react'
import { post } from 'Shared/Common'
import { CommentType } from 'Shared/Types'
import styles from './ReportModal.css'

type Props = {
  show: boolean,
  comment: CommentType,
  onClose: () => void
}

const ATTR_MODAL_OPEN = 'data-modalopen'

const ReportModal = (props: Props) => {
  const textRef = useRef()

  const handleSubmit = () => {
    post(`/api/comments/${props.comment.id}/report`, {
      body: JSON.stringify({
        text: textRef.current.value
      })
    }).then(response => {
      if (response.ok) {
        props.onClose()
      } else {
        alert('Unknown error')
      }
    })
  }

  useEffect(() => {
    document.body.setAttribute(ATTR_MODAL_OPEN, true)

    return () => {
      document.body.removeAttribute(ATTR_MODAL_OPEN)
    }
  }, [])

  return (
    <div className={styles.popupContainer} data-show={props.show}>
      <div>
        <div>Report comment by <b>{props.comment ? props.comment.commenter : 'user'}</b>:</div>
        <textarea ref={textRef} />
        <div>
          <button onClick={handleSubmit}>Submit</button>
          <button onClick={() => props.onClose()}>Cancel</button>
        </div>
      </div>
    </div>
  )
}

export default ReportModal
