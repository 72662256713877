/*
 * Source: https://loading.io/css/
 */

// @flow
import classnames from 'classnames'
import styles from './LoadingSpinner.css'

type Props = {
  className?: string
}

const LoadingSpinner = (props: Props) => (
  <div className={classnames(styles.ldsEllipsis, props.className)}>
    <div />
    <div />
    <div />
    <div />
  </div>
)

export default LoadingSpinner
