// @flow
import autobind from 'class-autobind'
import EventEmitter from 'events'

export default class CountdownWorker extends EventEmitter {
  backgroundWorker: Worker
  intervalID: IntervalID
  useWebWorker: boolean

  constructor (forceOld: boolean = false) {
    super()
    autobind(this)
    this.useWebWorker = typeof (Worker) !== 'undefined' && !forceOld
    console.info('useWebWorker', this.useWebWorker)
  }

  start (seconds: number) {
    if (this.useWebWorker) {
      if (typeof (this.backgroundWorker) == 'undefined') {
        this.backgroundWorker = new Worker('/public/CountdownWorker.js')
      }

      this.backgroundWorker.onmessage = e => {
        if (e.data === 'done' || e.data === 'stopped') {
          // Cleanup and send last event
          this.backgroundWorker.terminate()
          this.backgroundWorker = undefined
          // $FlowFixMe
          this.emit(e.data)
        } else if (parseInt(e.data) <= seconds) {
          this.emit('interval', parseInt(e.data))
        }
      }
      this.backgroundWorker.postMessage('start:' + seconds)
    } else {
      // Worker not supported, revert to setInterval method
      let elapsed = 0

      this.intervalID = setInterval(() => {
        elapsed++

        if (elapsed <= seconds) {
          this.emit('interval', elapsed)
        } else {
          clearInterval(this.intervalID)
          this.emit('done', true)
        }
      }, 1000)
    }
  }

  stop () {
    if (this.useWebWorker) {
      this.backgroundWorker.postMessage('stop')
    } else {
      clearInterval(this.intervalID)
      this.emit('done', false)
    }
  }
}
