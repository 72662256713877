// @flow
import { StatusLabel, StatusLabelColors } from 'Components/StatusLabel/StatusLabel'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { post } from 'Shared/Common'
import { ServiceType } from 'Shared/Types'
import CardContainer from '../CardContainer/CardContainer'
import styles from './Services.css'

/* ToDO:
 * - Make 'Starting' and 'Stopping' status per service instead of "global" state
 *   so that it can be displayed for multiple services at the same time
 */

const UPDATE_MS = 2000

const formatStatus = (status: number) => {
  switch (status) {
    case 0:
      return <StatusLabel color={StatusLabelColors.Red}>Off</StatusLabel>
    case 1:
      return <StatusLabel color={StatusLabelColors.Green}>On</StatusLabel>
    case 2: // No-service-file
    case 3: // Unknown-status
    default: // Error-default
      return <StatusLabel color={StatusLabelColors.Gray}>Error</StatusLabel>
  }
}

const Services = () => {
  const [user, initialServices, error] = useSelector((state) => [
    state.user, state.services, state.error
  ])
  const [services, setServices] = useState(initialServices)
  const [serviceUpdatingIndex, setServiceUpdatingIndex] = useState(-1)
  const [serviceUpdatingStatus, setServiceUpdatingStatus] = useState(-1)
  const location = useLocation()

  const checkForUpdates = () => {
    fetch('/api/services/get')
      .then(response => response.json())
      .then(json => setServices(json))

    setTimeout(() => checkForUpdates(), UPDATE_MS)
  }

  useEffect(() => {
    document.title = 'Services - ' + document.title
    setTimeout(() => checkForUpdates(), UPDATE_MS)
  }, [])

  const toggleService = (start: boolean, index: number, service: ServiceType) => {
    post(`/api/services/${start ? 'start' : 'stop'}`, {
      body: JSON.stringify({ service: service.id })
    }).then(response => {
      if (!response.ok) {
        alert('You do not have the required permissions.')
      } else {
        setServiceUpdatingIndex(index)
        setServiceUpdatingStatus(service.status)
        resetUpdating()
      }
    })
  }

  const resetUpdating = () => {
    setTimeout(() => {
      setServiceUpdatingIndex(-1)
      setServiceUpdatingStatus(-1)
    }, 10000)
  }

  const renderButtons = (index: number, service: ServiceType) => {
    return (
      <>
        <button
          disabled={!service.hasStart || service.running}
          onClick={() => toggleService(true, index, service)}>Start</button>
        <button
          disabled={!service.hasStop || !service.running}
          onClick={() => toggleService(false, index, service)}>Stop</button>
      </>
    )
  }

  const shouldRenderButtons = user && user.role >= 2

  return (
    <CardContainer title='Services' className={styles.services}>
      {error &&
        <div>
          {error}
        </div>
      }

      <div>
        <table className={styles.serviceTable}>
          <thead>
            <tr>
              {/* eslint-disable react/no-unknown-property */}
              <th align='left'>Status</th>
              <th align='left'>Name</th>
              <th align='left'>Actions</th>
              {/* eslint-enable react/no-unknown-property */}
            </tr>
          </thead>
          <tbody>
            {services && services.map((service, index) => {
              let status
              // Check if the service status has changed
              if (serviceUpdatingIndex === index && service.status === serviceUpdatingStatus) {
                status = <StatusLabel color={StatusLabelColors.Gray}>{service.status === 0 ? 'Starting' : 'Stopping'}</StatusLabel>
              } else {
                status = formatStatus(service.status)
              }

              return (
                <tr key={index}>
                  <td>{status}</td>
                  <td><a href={location.pathname + '/' + service.id}>{service.name}</a></td>
                  <td>{shouldRenderButtons && renderButtons(index, service)}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>

      <div style={{ color: '#666', fontSize: '0.9em', marginTop: '4px', padding: '0 4px' }}>
        Note: Clicking <b>Stop</b> during startup on some services may cause the status to be incorrect.
        Status will reset after 10 seconds as a timeout.
      </div>
    </CardContainer>
  )
}

export default Services
