function appReducer (state = null, action) {
  switch (action.type) {
    case 'LOGIN':
      return Object.assign({}, state, action.newState)
    default:
      return state
  }
}

export default appReducer
