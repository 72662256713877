// @flow
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ReactSVG } from 'react-svg'
import { fdelete, post } from 'Shared/Common'
import styles from './Comments.css'
import ReportModal from './ReportModal/ReportModal'

type Props = {
  userId: number
}

const CommentsPerPage = 20

const Comments = (props: Props) => {
  let commentsRef = useRef()
  let editCommentRef = useRef()
  let submitCommentRef = useRef()

  const {
    user,
    comments: initialComments,
    commentsCount: initialCommentsCount
  } = useSelector(state => state)
  const [comments, setComments] = useState(initialComments)
  const [commentsCount, setCommentsCount] = useState(initialCommentsCount)
  const [page, setPage] = useState(1)
  const [reportShow, setReportShow] = useState(false)
  const [reportComment, setReportComment] = useState(null)
  const [highlightComment, setHighlightComment] = useState('')
  const [editing, setEditing] = useState(-1)
  const [scrollToTopTrigger, triggerScrollToTop] = useState(false)
  const location = useLocation()

  useEffect(() => {
    if (!scrollToTopTrigger)
      return

    commentsRef.current.scrollIntoView()
    triggerScrollToTop(false)
  }, [scrollToTopTrigger])

  useEffect(() => {
    setHighlightComment(new URLSearchParams(location.search).get('comment'))
  }, [])

  const getComments = (page: number, scrollToTop: boolean = false) => {
    const offset = (page - 1) * CommentsPerPage

    triggerScrollToTop(scrollToTop)
    setPage(page)

    fetch('/api/comments/get?userid=' + props.userId + '&offset=' + offset)
      .then(response => response.json())
      .then(({ comments, totalCount }) => {
        setComments(comments)
        setCommentsCount(totalCount)
      })
  }

  const onDeleteComment = (comment: number) => {
    if (confirm('Are you sure?') === false)
      return

    fdelete('/api/comments/delete', {
      body: JSON.stringify({
        comment: parseInt(comment)
      })
    }).then(response => {
      if (response.ok) {
        getComments(page)
      } else if (response.status === 401 || response.status === 403) {
        alert('You do not have permission to do this.')
      } else {
        alert('Unknown error')
      }
    })
  }

  const onEditComment = (comment: number) => setEditing(parseInt(comment))

  const onEditCommentSave = (comment: number) => {
    post('/api/comments/' + comment, {
      body: JSON.stringify({
        text: editCommentRef.current.value
      })
    }).then(response => {
      if (response.ok) {
        let newComments = comments
        newComments[newComments.findIndex(c => c.id === comment)].text = editCommentRef.current.value

        setComments(newComments)
        setEditing(null)
        editCommentRef.current = null
      } else {
        alert('Error saving comment')
      }
    })
  }

  const onReportComment = (comment: number) => {
    setReportShow(true)
    setReportComment(comments.find(x => x.id === comment))
  }

  const onSubmitComment = () => {
    // Check that the comment isn't empty
    if (!submitCommentRef.current.value || submitCommentRef.current.value.trim().length < 1) {
      alert('Comment can\'t be empty!')
      return
    }

    post('/api/comments/submit', {
      body: JSON.stringify({
        userId: props.userId,
        text: submitCommentRef.current.value
      })
    }).then(response => {
      if (response.ok) {
        getComments(page)
        submitCommentRef.current.value = ''
      } else {
        alert('Unknown error')
      }
    })
  }

  const getButtons = () => {
    const pages = Math.ceil(commentsCount / CommentsPerPage)
    const pageButtons = []

    for (let i = 0; i < pages; i++) {
      if ((i + 1) === page) {
        pageButtons.push(
          <span className={styles.pageButton} key={i} disabled>{i + 1}</span>
        )
      } else {
        pageButtons.push(
          <span className={styles.pageButton} key={i} onClick={() => getComments(i + 1, true)}>{i + 1}</span>
        )
      }
    }

    return (
      <div className={styles.buttonsContainer}>
        <span className={styles.arrow} onClick={() => getComments(Math.max(page - 1, 1))}>&lArr;</span>
        {pageButtons}
        <span className={styles.arrow} onClick={() => getComments(Math.min(page + 1, pages))}>&rArr;</span>
      </div>
    )
  }

  const canDeleteComments = user && user.id && props.userId && user.id === props.userId

  return (
    <div className={styles.commentsContainer} ref={commentsRef}>
      {reportShow && <ReportModal show={reportShow} comment={reportComment} onClose={() => {
        setReportShow(false)
        setReportComment(null)
      }} />}

      <div>
        <h3>Post a comment</h3>
        <textarea className={styles.commentReply} ref={submitCommentRef} />
        <button onClick={onSubmitComment}>Submit</button>
      </div>

      <h3>Comments</h3>
      <ul className={styles.commentsList}>
        {comments.map((comment, index) => {
          const avatar = comment.avatar || '/public/avatar_default.jpg'
          const userUrl = comment.commenterId ? `/users/${comment.commenterId}` : null

          return (
            <li className={styles.comment} key={index} data-highlight={highlightComment == comment.id}>
              <div style={{ display: 'flex', position: 'relative' }}>
                <div className={styles.commentAvatar}>
                  <a href={userUrl}>
                    <img src={avatar} />
                  </a>
                </div>

                <div style={{ width: '100%' }}>
                  <a className={styles.commentCommenter} href={userUrl}>{comment.commenter}</a>
                  <span className={styles.commentDate} title={comment.date.toString()}>{moment(comment.date).format('llll')}</span>
                  <span className={styles.commentId}>#{comment.id}</span>
                  {editing !== comment.id &&
                    <div className={styles.commentText}>{comment.text}</div>
                  }
                  {editing === comment.id &&
                    <div className={styles.commentEditWrapper}>
                      <textarea className={styles.commentEdit} defaultValue={comment.text} ref={editCommentRef} />
                      <a onClick={() => onEditCommentSave(comment.id)}>Save</a>
                      <a onClick={() => onEditComment(null)}>Cancel</a>
                    </div>
                  }
                </div>

                <span className={styles.commentButtons}>
                  {user && comment.commenterId === user.id &&
                    <button title='Edit' data-nostyling onClick={() => onEditComment(comment.id)}>
                      <ReactSVG src='/public/edit-button.svg' />
                    </button>
                  }
                  {user && user.id !== comment.commenterId &&
                    <button title='Report' data-nostyling onClick={() => onReportComment(comment.id)}>
                      <ReactSVG src='/public/megaphone.svg' />
                    </button>
                  }
                  {(canDeleteComments || (user && comment.commenterId === user.id)) &&
                    <button title='Delete' data-nostyling onClick={() => onDeleteComment(comment.id)}>
                      <ReactSVG src='/public/rubbish-bin.svg' />
                    </button>
                  }
                </span>
              </div>
            </li>
          )
        })}
      </ul>
      {commentsCount > CommentsPerPage &&
        getButtons()
      }
    </div>
  )
}

export default Comments
