// @flow
import ArticleEditor from 'Components/ArticleEditor/ArticleEditor'
import CardContainer from 'Components/CardContainer/CardContainer'
import { useState } from 'react'
import styles from './ArticleWriterPage.css'

const formatList = (text: string): string => {
  const regex = /\[list\](.*?)\[\/list\]/gis
}

const format = (text: string): string => {
  return text ? text
    // Sanitize HTML
    .replace(/&/gi, '&amp;')
    .replace(/</gi, '&lt;')
    .replace(/>/gi, '&gt;')
    .replace(/"/gi, '&quot;')
    .replace(/'/gi, '&apos;')

    .replace(/\[img=(.*?)\]/gi, '<img src="$1" />')
    .replace(/\[url=(.*?)\](.*?)\[\/url\]/gi, '<a href="$1">$2</a>')
    .replace(/\[b\](.*?)\[\/b\]/gi, '<b>$1</b>')
    .replace(/\[i\](.*?)\[\/i\]/gi, '<i>$1</i>')
    .replace(/\[heading\](.*?)\[\/heading\]/gi, '<h2>$1</h2>')
    .replace(/\[size=(\d+)\](.*?)\[\/size\]/gi, '<font size="$1">$2</font>')
    .replace(/\[list\](.*?)\[\/list\]/gis, '<ul>$1</ul>')
    .replace(/\[\*\](.*)/gi, '<li>$1</li>') : ''
}

const ArticleWriterPage = () => {
  const [content, setContent] = useState('')

  return (
    <CardContainer className={styles.articleWriter} title='Write article'>
      <div className={styles.title}>
        <div>Title</div>
        <input type='text' />
      </div>

      <div>
        <ArticleEditor onChange={(value) => setContent(value)} />
      </div>

      <div>
        <h2>Preview</h2>
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{ __html: format(content) }}
        />
      </div>

      <div>
        <button>Post</button>
      </div>
    </CardContainer>
  )
}

export default ArticleWriterPage
