// @flow
import CardContainer from 'Components/CardContainer/CardContainer'
import { useState } from 'react'
import styles from './RecoverPage.css'

const RecoverPage = () => {
  const [email, setEmail] = useState('')
  const [errors, setErrors] = useState([])

  const handleSubmit = (event: Event) => {
    event.preventDefault()

    // Check if any input is null
    if (!email) {
      setErrors(['Missing email.'])
    } else {
      fetch('/api/users/recover', {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify({
          email
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => {
        if (response.ok || response.status === 500) {
          return response
        } else {
          return response.json().then(json => ({
            ok: response.ok,
            status: response.status,
            body: json
          }))
        }
      }).then(response => {
        if (response.ok) {
          // Success, navigate to login screen
          alert('Recovery email sent.')
          window.location = '/login'
        } else if (response.status === 422) {
          // Show errors to user
          setErrors(Object.values(response.body.errors).map((err: any) => err.msg))
        } else {
          throw new Error('Unknown error. Check console.')
        }
      }).catch(error => {
        alert(error)
        console.error(error)
      })
    }
  }

  return (
    <CardContainer className={styles.recoverPage}>
      <h2>Recover Account</h2>

      {errors?.length > 0 &&
        <ul className={styles.errors}>
          {errors.map((x, i) => (
            <li key={i}>{x}</li>
          ))}
        </ul>
      }

      <form onSubmit={handleSubmit}>
        <div>
          <label>Email</label>
          <input
            autoComplete='username'
            type='email'
            label='Email'
            name='email'
            value={email}
            onChange={(e) => setEmail(e.target.value)} />
        </div>

        <div>
          <input type='submit' value='Recover' />
          <button className={styles.backButton} type='button'>
            <a href='/login'><span>Back</span></a>
          </button>
        </div>
      </form>
    </CardContainer>
  )
}

export default RecoverPage
