// @flow
import styles from './Timer.css'

type Props = {
  hhmmss: string
}

const Timer = (props: Props) => {
  const hhmmssSplit = props.hhmmss.split(':')

  return (
    <div className={styles.timer}>
      <span>
        <div>
          {hhmmssSplit[0]}<span>:</span>
        </div>
        <div>HOURS</div>
      </span>

      <span>
        <div>
          {hhmmssSplit[1]}<span>:</span>
        </div>
        <div>MINUTES</div>
      </span>

      <span>
        <div>
          {hhmmssSplit[2]}
        </div>
        <div>SECONDS</div>
      </span>
    </div>
  )
}

export default Timer
