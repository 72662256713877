// @flow
import CardContainer from 'Components/CardContainer/CardContainer'
import styles from './Admin.css'

const Admin = () => {
  return (
    <CardContainer className={styles.admin} title='Admin'>
      <a href='/admin/reports'>Reports</a>
      <br></br>
      <a href='/admin/stats'>Stats</a>
    </CardContainer>
  )
}

export default Admin
