// @flow
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import styles from './Settings.css'
import autobind from 'class-autobind'
import classnames from 'classnames'
import { post } from 'Shared/Common'
import CardContainer from 'Components/CardContainer/CardContainer'
// $FlowFixMe
import { UserType } from 'Shared/Types'

type Props = {
  user: UserType
}

type State = {
  email: string,
  password: string,
  newPassword: string,
  repeatPassword: string,
  privacyProfile: number,
  privacyComments: number,
  errors: {
    email: boolean,
    emailMsg: string,
    password: boolean,
    passwordMsg: string,
    newPassword: boolean,
    newPasswordMsg: string
  }
}

class Settings extends PureComponent<Props, State> {
  constructor (props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      newPassword: '',
      repeatPassword: '',
      privacyProfile: this.props.user.privacyProfile,
      privacyComments: this.props.user.privacyComments,
      errors: {
        email: false,
        emailMsg: '',
        password: false,
        passwordMsg: '',
        newPassword: false,
        newPasswordMsg: ''
      }
    }
    autobind(this)
  }

  handleInputChange (event) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    this.setState({
      [name]: value
    })
  }

  handleSubmitEmail (event) {
    event.preventDefault()

    const { email } = this.state

    post('/api/account/update/email', {
      body: JSON.stringify({
        email
      })
    }).then(response => {
      if (response.ok) {
        window.location.reload()
      } else {
        if (response.status === 409) {
          response.text().then(text => {
            this.setState({
              errors: Object.assign({}, this.state.errors, {
                email: true,
                emailMsg: text
              })
            })
          })
        } else if (response.status === 422) {
          response.json().then(json => {
            this.setState({
              errors: Object.assign({}, this.state.errors, {
                email: true,
                emailMsg: json.errors.email.msg
              })
            })
          })
        }
      }
    })
  }

  handleSubmitPassword (event) {
    event.preventDefault()

    const { password, newPassword, repeatPassword } = this.state

    post('/api/account/update/password', {
      body: JSON.stringify({
        password,
        newPassword,
        repeatPassword
      })
    }).then(response => {
      if (response.ok) {
        window.location.reload()
      } else {
        if (response.status === 401 || response.status === 422) {
          response.json().then(json => {
            let errors = this.state.errors

            if (response.status === 401) {
              errors = Object.assign({}, errors, {
                password: true,
                passwordMsg: json.message
              })
            } else if (response.status === 422) {
              if (json.errors.password) {
                errors = Object.assign({}, errors, {
                  password: true,
                  passwordMsg: json.errors.newPassword.msg
                })
              }

              if (json.errors.newPassword) {
                errors = Object.assign({}, errors, {
                  newPassword: true,
                  newPasswordMsg: json.errors.newPassword.msg
                })
              }
            }

            this.setState({
              errors: Object.assign({}, this.state.errors, errors)
            })
          })
        }
      }
    })
  }

  savePrivacy (event) {
    const { privacyProfile, privacyComments } = this.state

    post('/api/users/privacy', {
      body: JSON.stringify({
        privacyProfile,
        privacyComments
      })
    }).then(response => {
      if (response.ok) {
        alert('Updated!')
      } else {
        alert('Something went wrong.')
      }
    })
  }

  render () {
    const {
      email,
      password,
      newPassword,
      repeatPassword,
      privacyProfile,
      privacyComments,
      errors
    } = this.state

    return (
      <CardContainer title='Settings' className={styles.settings}>

        <h3>Email</h3>
        <form onSubmit={this.handleSubmitEmail}>
          <div className={styles.row}>
            <span className={styles.leftText}>Current Email</span>
            <input
              type='email'
              label='Current Email'
              value={this.props.user.email}
              readOnly />
          </div>

          <div className={styles.row}>
            <span className={styles.leftText}>New Email</span>
            <input
              className={classnames({ [styles.error]: errors.email })}
              type='email'
              label='New Email'
              name='email'
              value={email}
              onChange={this.handleInputChange}
              autoComplete='email'
              onBlur={() => {
                this.setState({
                  errors: Object.assign({}, errors, {
                    email: false
                  })
                })
              }} />
            <span className={styles.errorMessage} data-hidden={!errors.email}>{errors.emailMsg}</span>
          </div>
          <div>
            <input type='submit' value='Change' />
          </div>
        </form>

        <h3>Password</h3>
        <form onSubmit={this.handleSubmitPassword}>
          <div className={styles.row}>
            <span className={styles.leftText}>Current Password</span>
            <input
              className={classnames({ [styles.error]: errors.password })}
              type='password'
              label='Password'
              name='password'
              value={password}
              onChange={this.handleInputChange}
              autoComplete='current-password'
              onBlur={() => {
                this.setState({
                  errors: Object.assign({}, errors, {
                    password: false
                  })
                })
              }} />
            <span className={styles.errorMessage} data-hidden={!errors.password}>{errors.passwordMsg}</span>
          </div>

          <div className={styles.row}>
            <span className={styles.leftText}>New Password</span>
            <input
              className={classnames({ [styles.error]: errors.newPassword })}
              type='password'
              label='New Password'
              name='newPassword'
              value={newPassword}
              onChange={this.handleInputChange}
              autoComplete='new-password'
              onBlur={() => {
                this.setState({
                  errors: Object.assign({}, errors, {
                    newPassword: false
                  })
                })
              }} />
            <span className={styles.errorMessage} data-hidden={!errors.newPassword}>{errors.newPasswordMsg}</span>
          </div>

          <div className={styles.row}>
            <span className={styles.leftText}>Repeat Password</span>
            <input
              type='password'
              label='Repeat Password'
              name='repeatPassword'
              value={repeatPassword}
              onChange={this.handleInputChange}
              autoComplete='new-password' />
          </div>
          <div>
            <input type='submit' value='Change' />
          </div>
        </form>

        <h3>Privacy</h3>
        <div className={styles.privacy}>
          <span>Who can:</span>
          <span className={styles.settingsList}>
            <div>
              <span>See my profile:</span>
              <select onChange={this.handleInputChange} name='privacyProfile' value={privacyProfile}>
                <option value={1}>Anyone</option>
                <option value={2}>Friends only</option>
                <option value={3}>No one</option>
              </select>
            </div>
            <div>
              <span>Post comments on my profile:</span>
              <select onChange={this.handleInputChange} name='privacyComments' value={privacyComments}>
                <option value={1}>Anyone</option>
                <option value={2}>Friends only</option>
                <option value={3}>No one</option>
              </select>
            </div>
          </span>
        </div>
        <button onClick={this.savePrivacy}>Save</button>
      </CardContainer>
    )
  }
}

const mapStateToProps = state => {
  const { user } = state
  return {
    user
  }
}

const SettingsExport = connect(
  mapStateToProps
)(Settings)

export default SettingsExport
