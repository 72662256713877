// @flow
import React, { PureComponent } from 'react'
import styles from './Login.css'
import autobind from 'class-autobind'
import CardContainer from '../../CardContainer/CardContainer'
import { post } from 'Shared/Common'

type State = {
  email: string,
  password: string,
  errors: string[]
}

function isNull (value: string,
                 index: number,
                 array: string[]): boolean {
  return !value
}

export default class Login extends PureComponent<void, State> {
  constructor (props: any) {
    super(props)

    this.state = {
      email: '',
      password: '',
      errors: []
    }
    autobind(this)
  }

  handleInputChange (event: Event) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    this.setState({
      [name]: value
    })
  }

  handleSubmit (event: Event) {
    event.preventDefault()

    const { email, password } = this.state
    const checknull = [email, password]

    // Check if any input is null
    if (checknull.some(isNull)) {
      this.setState({
        errors: ['All fields needs to be filled.']
      })
    } else {
      post('/api/users/login', {
        body: JSON.stringify({
          email,
          password
        })
      }).then((response: Response) => {
        return (response.status === 422 ? response.json() : response.text())
          .then(data => ({
            ok: response.ok,
            status: response.status,
            body: data
          }))
      }).then((response: any) => {
        if (response.ok) {
          // Success, navigate to home
          window.location = '/'
        } else if (response.status === 401 || response.status === 404) {
          this.setState({
            errors: [response.body]
          })
        } else if (response.status === 422) {
          // Show errors to user
          this.setState({
            errors: Object.values(response.body.errors).map((err: any) => err.msg)
          })
        } else {
          throw new Error('Unknown error. Check console.')
        }
      }).catch(error => {
        alert(error)
        console.error(error)
      })
    }
  }

  render () {
    const { email, password, errors } = this.state
    return (
      <CardContainer className={styles.loginPage}>
        <h2>Login</h2>
        {errors && errors.length > 0 && <section className={styles.errors}>
          <ul>
            {errors.map((err, i) => (
              <li key={i}>{err}</li>
            ))}
          </ul>
        </section>}
        <form onSubmit={this.handleSubmit}>
          <div>
            <label>Email</label>
            <input
              autoComplete='username'
              type='email'
              label='Email'
              name='email'
              value={email}
              onChange={this.handleInputChange} />
          </div>
          <div>
            <label>Password<a href='/recover'>Forgot password?</a></label>
            <input
              autoComplete='current-password'
              type='password'
              label='Password'
              name='password'
              value={password}
              onChange={this.handleInputChange} />
          </div>
          <div>
            <input type='submit' value='Login' />
            <button className={styles.registerButton} type='button'>
              <a href='/register'><span>Register</span></a>
            </button>
          </div>
        </form>
      </CardContainer>
    )
  }
}
