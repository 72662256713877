// @flow
import { Line } from 'rc-progress'
import styles from './ProgressItem.css'

type Props = {
  item: {
    title: string,
    url: string,
    progress: number,
    status: string,
    filename: string,
    format: string
  }
}

const ProgressItem = (props: Props) => {

  const formatStatus = (status: string, progress: number) => {
    switch (status) {
      case 'running':
        return `${progress}%`
      case 'done':
        return 'Done'
      case 'failed':
        return 'Failed'
      default:
        return null
    }
  }

  const { title, url, progress, status, filename, format } = props.item

  return (
    <li className={styles.progressItem}>
      <a className={styles.ytLink} href={url} target='_blank' rel='noreferrer'>
        <img src='/public/youtube_icon_red.png' />
      </a>
      <a className={styles.link} href={filename || '#'}>{title}{format ? ` [${format}]` : ''}</a>

      {status === 'running' && <button>Cancel</button>}

      <div>
        <Line className={styles.progressBar}
          percent={status === 'done' ? 100 : progress}
          strokeWidth='3'
          trailWidth='3' />

        <span>{formatStatus(status, progress)}</span>
      </div>
    </li>
  )
}

export default ProgressItem
