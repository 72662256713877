// @flow
import ArticleEditor from 'Components/ArticleEditor/ArticleEditor'
import CardContainer from 'Components/CardContainer/CardContainer'
import { useSelector } from 'react-redux'
import { ArticleType } from 'Shared/Types'
import styles from './ArticlesPage.css'

type ReduxProps = {
  articles: ArticleType[]
}

const ArticlesPage = () => {
  const { articles } = useSelector((state: ReduxProps) => state)

  return (
    <CardContainer className={styles.articles} title='Articles'>
      <a href='/articles/write'>Write new article</a>

      <ul>
        {articles.map((art, index) =>
          <li key={index}>
            <a href={'/article/' + art.url}>{art.title}</a>
            <div>
              Writer: <a href={'/users/' + art.creatorId}>{art.creator}</a>
            </div>
          </li>
        )}
      </ul>

      <ArticleEditor />
    </CardContainer>
  )
}

export default ArticlesPage
