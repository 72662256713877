// @flow
import styles from './Footer.css'

type Props = {
  version: string
}

const Footer = (props: Props) => {
  return (
    <footer className={styles.footer}>
      <div>
        <span>
          Icons made by the following users from <a href='https://www.flaticon.com/' title='Flaticon'>Flaticon</a> is licensed by <a href='http://creativecommons.org/licenses/by/3.0/' title='Creative Commons BY 3.0' target='_blank'>CC 3.0 BY</a>
        </span>
        <ul>
          <li><a href='https://www.flaticon.com/authors/gregor-cresnar' title='Gregor Cresnar'>Gregor Cresnar</a></li>
          <li><a href='http://www.freepik.com' title='Freepik'>Freepik</a></li>
          <li><a href='https://www.flaticon.com/authors/dave-gandy' title='Dave Gandy'>Dave Gandy</a></li>
          <li><a href='https://www.flaticon.com/authors/smashicons' title='Smashicons'>Smashicons</a></li>
          <li><a href='https://www.flaticon.com/authors/pixel-perfect' title='Pixel perfect'>Pixel perfect</a></li>
        </ul>
        <span className={styles.version}>Commit: {props.version}</span>
      </div>
    </footer>
  )
}

export default Footer
