// @flow
import classnames from 'classnames'
import LoadingSpinner from 'Components/LoadingSpinner/LoadingSpinner'
import { Line } from 'rc-progress'
import { useEffect, useRef, useState } from 'react'
import { ReactSVG } from 'react-svg'
import YouTube from 'react-youtube'
import { humanFileSize, parseBoolean, post } from 'Shared/Common'
import CardContainer from '../CardContainer/CardContainer'
import AdminProgress from './AdminProgress'
import styles from './YouTubeDl.css'

type Quality = {
  filesize: number,
  id: string,
  text: string,
  ext: string
}
const QualityEmptyArray: Quality[] = []

type VideoType = {
  author: string,
  authorPage: string,
  cached: boolean,
  length: string,
  thumbnail: string,
  title: string,
  url: string,
  displayId: string
}

const formatDuration = (duration: number) => {
  const hours = Math.floor(duration / 3600)
  duration %= 3600
  const minutes = Math.floor(duration / 60)
  const seconds = duration % 60

  const pad = (str) => {
    str = str + ''
    return ('00' + str).substring(str.length)
  }

  if (hours > 0) {
    return pad(hours) + ':' + pad(minutes) + ':' + pad(seconds)
  } else {
    return pad(minutes) + ':' + pad(seconds)
  }
}

const formatTitle = (title: string) => {
  // Needs to be have escaped escape char for regex
  const illegalCharacters = ['\\/', '\\\\', '\\*', '\\?', '\\"', '\\<', '\\>']
  const replace = {
    '\\|': '-',
    '&#39;': "'",
    '&quot;': "'",
    '&lt;': '(',
    '&gt;': ')',
    '\\+': ' ',
    '\\:': '-',
    '&amp;': '&'
  }
  for (let i = 0; i < illegalCharacters.length; i++) {
    const find = illegalCharacters[i]
    title = title.replace(new RegExp(find, 'g'), '')
  }
  Object.keys(replace).forEach(key => {
    title = title.replace(new RegExp(key, 'g'), replace[key])
  })

  return title.trim()
}

const maxFilesize = (a, b) => a.filesize > b.filesize ? a : b

const sortFormats = (x: any, y: any): number => {
  // Always sort to bottom
  if (x.id === 'audio_only') return 1
  if (y.id === 'audio_only') return -1

  const mx = /(\d+)p(\d+)?/g.exec(x.text)
  const my = /(\d+)p(\d+)?/g.exec(y.text)
  // Add resolution and FPS (if available) together
  const xx = parseInt(mx[1]) + parseInt(mx[2] || 0)
  const yy = parseInt(my[1]) + parseInt(my[2] || 0)

  // Sort highest value to top
  if (xx > yy) {
    return -1
  }
  if (xx < yy) {
    return 1
  }
  return 0
}

const InitialVideo: VideoType = {
  author: 'Author',
  authorPage: '',
  cached: false,
  length: '0:00',
  thumbnail: '/public/placeholder.jpg',
  title: 'Title',
  url: '',
  displayId: ''
}

const YouTubeDl = () => {
  const progressElement = useRef()
  const resultElement = useRef()
  const [combining, setCombining] = useState(false)
  const [converting, setConverting] = useState(false)
  const [querying, setQuerying] = useState(false)
  const [downloading, setDownloading] = useState(false)
  const [downloadLink, setDownloadLink] = useState('')
  const [downloadProgress, setDownloadProgress] = useState(0)
  const [failed, setFailed] = useState(false)
  const [link, setLink] = useState('')
  const [part, setPart] = useState('')
  const [poster, setPoster] = useState('')
  const [qualities, setQualities] = useState(QualityEmptyArray)
  const [showWarning, setShowWarning] = useState(false)
  const [showVideoInformation, setShowVideoInformation] = useState(false)
  const [video, setVideo] = useState(InitialVideo)

  const handleDownloadClick = (event: Event) => {
    if (!downloadLink) {
      event.preventDefault()
    }
  }

  const handleGetClick = () => {
    setQuerying(true)

    fetch('/api/youtube-dl/get?video=' + link)
      .then(response => response.json())
      .then(response => {
        const qualities = []

        // Add audio_only
        qualities.push({
          filesize: response.formats.find(x => x.ext === 'm4a').filesize,
          id: 'audio_only',
          text: 'Audio Only',
          ext: 'mp3'
        })

        // Find the highest quality per unique format_note (360p, 480p etc), assume higher filesize means higher quality
        const validFormats = response.formats.filter(x => x.container && x.ext === 'mp4' && /\d+p/.test(x.format_note))
        const highestQualities = [...new Set(validFormats.map(x => x.format_note))]
          .map(q => validFormats.filter(f => f.format_note === q).reduce(maxFilesize, 0))

        qualities.push(...highestQualities.map(x => {
          return {
            filesize: x.filesize,
            id: x.format_id,
            text: x.format_note,
            ext: x.ext
          }
        }))

        setQuerying(false)
        setQualities(qualities.sort(sortFormats))
        setVideo({
          author: response.uploader,
          authorPage: response.channel_url,
          cached: false,
          length: formatDuration(response.duration),
          thumbnail: response.thumbnails[0].url,
          title: response.fulltitle,
          url: response.webpage_url,
          displayId: response.display_id
        })
      })
  }

  const monitorProgress = (id: string, timestamp: ?string) => {
    fetch(`/api/youtube-dl/progress?id=${id}&timestamp=${timestamp || ''}`)
      .then(result => result.json())
      .then(result => {
        switch (result.data.status) {
          case 'done':
            video.cached = result.data.cached

            setDownloading(false)
            setDownloadLink(result.data.filename)
            setPoster(video.thumbnail)
            setVideo(video)
            window.scrollTo(0, resultElement.current.offsetTop - 12)
            break
          case 'running':
            setCombining(false)
            setConverting(false)
            setDownloading(true)
            setPart(result.data.part)
            setDownloadProgress(result.data.progress)
            monitorProgress(id, result.timestamp)
            break
          case 'failed':
            setDownloading(false)
            setDownloadProgress(0)
            setFailed(true)
            break
          case 'combining':
            setCombining(true)
            setConverting(false)
            setDownloading(true)
            setDownloadProgress(result.data.progress)
            monitorProgress(id, result.timestamp)
            break
          case 'converting':
            setCombining(false)
            setConverting(true)
            setDownloading(true)
            setDownloadProgress(result.data.progress)
            monitorProgress(id, result.timestamp)
            break
        }
      })
  }

  const download = (quality: string) => {
    const simulate = 0
    const formatText = quality === 'audio_only'
      ? 'Audio only'
      : qualities.find(x => x.id === quality).text

    post('/api/youtube-dl/download', {
      body: JSON.stringify({
        title: formatTitle(video.title),
        video: link,
        format: {
          id: quality,
          text: formatText
        },
        simulate
      })
    })
      .then(response => response.json())
      .then(result => {
        if (simulate === 0) {
          setDownloading(true)
          monitorProgress(result.id)
        }

        window.scrollTo(0, progressElement.current.offsetTop - 12)
      })
  }

  const progress = (part: string): string => {
    if (combining) {
      return 'Combining...'
    } else if (converting) {
      return 'Converting...'
    } else {
      return (downloadProgress || 0) + '%' + (part ? ` - Downloading ${part}...` : '')
    }
  }

  useEffect(() => {
    document.title = 'youtube-dl - ' + document.title
    setLink(window.localStorage.ytdLink ? window.localStorage.ytdLink : '')
    setShowWarning(window.localStorage.showWarning ? window.localStorage.showWarning : true)
  }, [])

  return (
    <CardContainer className={styles.ytd} title='YouTube Downloader'>
      <span className={styles.description}>
        YouTube Downloader can download videos from YouTube or Twitch. Uses <a href='https://rg3.github.io/youtube-dl/'>youtube-dl</a> server-side.
        {parseBoolean(showWarning) &&
          <div className={styles.importantBanner}>
            <b>Important: </b>
            Requests &amp; videos are stored and can be accessed by administrators. This is required because YouTube stores video &amp; audio separately and
            needs to be combined before it&#39;s served to you as a single file.
            <div>
              <a href='#' onClick={() => {
                window.localStorage.setItem('showWarning', false)
                setShowWarning(false)
              }}>Got It!</a>
            </div>
          </div>
        }
      </span>

      <div className={classnames(styles.container, styles.search)}>
        <h3>Search</h3>
        <div className={styles.videoLinkContainer}>
          <input
            className={styles.videoLinkInput}
            label='Link'
            name='link'
            value={link}
            placeholder='e.g. https://www.youtube.com/....'
            onChange={(e) => setLink(e.target.value)} />
          <button className={styles.getButton} onClick={handleGetClick} disabled={querying}>
            <ReactSVG className={styles.getIcon} src='/public/icons/search.svg' />
          </button>
        </div>
      </div>

      <div className={styles.playerWrapper}>
        <div className={styles.thumbnail}>
          {!video.displayId
            ? <img className={styles.videoThumbnail} src={video.thumbnail} />
            : (
              <YouTube
                iframeClassName={styles.embed}
                className={styles.embedWrapper}
                onReady={() => setShowVideoInformation(true)}
                onStateChange={(event) => {
                  setShowVideoInformation(event.data === YouTube.PlayerState.CUED)
                }}
                videoId={video.displayId}
                opts={{ height: '401', width: '100%' }} />
            )
          }

          <div className={styles.videoInformation} data-show={showVideoInformation}>
            <div>
              <a href={video.url ? video.url : undefined} target='_blank' rel='noreferrer'>{video.title}</a>
            </div>
            <div>
              <a href={video.authorPage ? video.authorPage : undefined} target='_blank' rel='noreferrer'>{video.author}</a>
            </div>
            <span>{video.length}</span>
          </div>

          {querying &&
            <div className={styles.loadingSpinnerWrapper}>
              <LoadingSpinner className={styles.loadingSpinner} />
            </div>
          }
        </div>
      </div>

      {qualities.length > 0 &&
        <div className={classnames(styles.container, styles.qualities)}>
          <h3>Qualities</h3>
          <ul className={styles.qualitiesList}>
            {qualities.map((quality, index) =>
              <li key={index} value={quality.id} onClick={() => download(quality.id)}>
                <div>
                  <span>{quality.text}</span>
                  <span>#{quality.id}</span>
                </div>
                <div>Type: <span>{quality.ext}</span>Size: <span>{humanFileSize(quality.filesize, true)}</span></div>
              </li>
            )}
          </ul>
        </div>
      }

      {failed &&
        <div className={styles.error}>
          <b>Failed</b>: Something went wrong, couldn&#39;t download video.
        </div>
      }

      {downloading &&
        <div className={classnames(styles.container, styles.progress)} ref={progressElement}>
          <h3>Progress</h3>
          <div className={styles.downloadProgress}>
            <span>{progress(part)}</span>
            <Line percent={downloadProgress || 0} />
          </div>
        </div>
      }

      {downloadLink &&
        <div className={classnames(styles.container, styles.result)} ref={resultElement}>
          <h3>Result</h3>

          {/* eslint-disable-next-line react/no-unknown-property */}
          <video controls poster={poster} src={downloadLink} type='video/mp4'>
            Your browser does not support the video tag.
          </video>

          <div className={styles.downloadLinks}>
            <div disabled={!downloadLink}>
              {/* eslint-disable-next-line react/no-unknown-property */}
              <a className={styles.downloadButton} href={downloadLink} download onClick={handleDownloadClick} disabled={!downloadLink}>
                <ReactSVG className={styles.downloadIconWrapper} src='/public/download.svg' />
                <span>Download</span>
                {video.cached &&
                  <span className={styles.cachedLabel}>Cached</span>
                }
              </a>
            </div>
          </div>
        </div>
      }

      <AdminProgress />
    </CardContainer>
  )
}

export default YouTubeDl
