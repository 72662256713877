// @flow
/* eslint-disable react/no-unknown-property */
import classnames from 'classnames'
import styles from './CardContainer.css'

type Props = {
  big?: boolean,
  className?: string,
  children?: any,
  title?: string,
  wide?: boolean
}

const CardContainer = (props: Props) => {
  const { className, big, wide, title, children } = props

  return (
    <div className={classnames(styles.card, className)}
      big={big ? '' : undefined}
      wide={wide ? '' : undefined}
    >
      {title && <h1>{title}</h1>}
      {children}
    </div>
  )
}

export default CardContainer
