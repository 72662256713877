// @flow
import classnames from 'classnames'
import { useState } from 'react'
import styles from './VolumeBar.css'

type Props = {
  className?: string,
  title?: string,
  initialVolume: number,
  onVolumeChange: (volume: number) => void
}

const VolumeBar = (props: Props) => {
  const [volume, setVolume] = useState(props.initialVolume || 0.5)

  const onVolumeChange = (e: any) => {
    const newVolume = parseFloat(e.target.value)
    setVolume(newVolume)
    props.onVolumeChange?.(newVolume)
  }

  const { className, title } = props

  return (
    <div className={classnames(styles.volumeBar, className)}>
      <p>{title || 'Volume'}</p>
      <span>&minus;</span>
      <input
        type='range'
        min='0.0' max='1'
        step='0.1'
        value={volume}
        onChange={onVolumeChange} />
      <span>+</span>
    </div>
  )
}

export default VolumeBar
