import { createStore } from 'redux'
import appReducer from './reducers/App'

const configureStore = (initialState) => {
  const store = createStore(
    appReducer,
    initialState
  )

  return store
}

export default configureStore
